<template>
  <div class="login">
    <template v-if="redirect">
      <div class="content-forbidden">
        <h2>{{ $t('common.oh') }}</h2>
        <p>{{ $t('forbidden.register-needed') }}</p>
      </div>
    </template>
    <h1>{{ $t('common.login') }}</h1>
    <div class="content">
      <div class="block">
        <h2>{{ $t('auth.access') }}</h2>
        <form @submit.prevent>
          <div class="input-container">
            <label for="user">{{ $t('auth.email') }}</label>
            <input
              type="text"
              name="user"
              v-model="login.email"
              @keydown="
                submittedLogin = false
                wrongpass = false
                nouser = false
              "
            />
            <div
              class="error"
              v-if="submittedLogin && !$v.login.email.required"
            >
              {{ $t('errors.required') }}
            </div>
            <div class="error" v-if="submittedLogin && !$v.login.email.email">
              {{ $t('errors.email') }}
            </div>
            <div class="error" v-if="nouser">
              {{ $t('errors.nouser') }}
            </div>
          </div>
          <div class="input-container">
            <label for="password">{{ $t('auth.password') }}</label>
            <input
              type="password"
              name="password"
              v-model="login.password"
              @keydown="
                submittedLogin = false
                wrongpass = false
                nouser = false
              "
            />
            <div
              class="error"
              v-if="submittedLogin && !$v.login.password.required"
            >
              {{ $t('errors.required') }}
            </div>
            <div class="error" v-if="wrongpass">
              {{ $t('errors.wrong-pass') }}
            </div>
          </div>
          <div class="forgot" @click="showForgotPass">
            {{ $t('auth.forgot-pass') }}
          </div>
          <button class="primary-btn" type="button" @click="sendUser">
            {{ $t('auth.access') }}
          </button>
        </form>
      </div>
      <div class="block">
        <h2>{{ $t('auth.register') }}</h2>
        <form @submit.prevent>
          <div class="input-container">
            <label for="name">{{ $t('common.name') }}*</label>
            <input
              type="text"
              name="name"
              v-model="register.name"
              @keydown="submittedRegister = false"
            />
            <div
              class="error"
              v-if="submittedRegister && !$v.register.name.required"
            >
              {{ $t('errors.required') }}
            </div>
          </div>
          <div class="input-container">
            <label for="email">{{ $t('auth.email') }}*</label>
            <input
              type="email"
              name="email"
              v-model="register.email"
              @keydown="submittedRegister = false"
            />
            <div
              class="error"
              v-if="submittedRegister && !$v.register.email.required"
            >
              {{ $t('errors.required') }}
            </div>
            <div
              class="error"
              v-if="submittedRegister && !$v.register.email.email"
            >
              {{ $t('errors.email') }}
            </div>
          </div>
          <div class="input-container">
            <label for="password">{{ $t('auth.password') }}*</label>
            <input
              type="password"
              name="password"
              v-model="register.password"
              @keydown="submittedRegister = false"
            />
            <p class="sub">{{ $t('auth.min-length') }}</p>
            <div
              class="error"
              v-if="submittedRegister && !$v.register.password.required"
            >
              {{ $t('errors.required') }}
            </div>
            <div
              class="error"
              v-if="submittedRegister && !$v.register.password.minLength"
            >
              {{ $t('errors.password-short') }}
            </div>
          </div>
          <div class="input-container">
            <label for="repassword">{{ $t('auth.re-password') }}*</label>
            <input
              type="password"
              name="repassword"
              v-model="register.rePassword"
              @keydown="submittedRegister = false"
            />
            <div
              class="error"
              v-if="submittedRegister && !$v.register.rePassword.required"
            >
              {{ $t('errors.required') }}
            </div>
            <div
              class="error"
              v-if="submittedRegister && !$v.register.rePassword.sameAs"
            >
              {{ $t('errors.sameAs') }}
            </div>
          </div>
          <div class="input-container">
            <label for="code">{{ $t('profile.institution-code') }}</label>
            <input
              type="text"
              name="institutionCode"
              v-model="register.institutionCode"
              @keydown="submittedRegister = false"
            />
          </div>
          <div class="input-container checkbox">
            <input
              type="checkbox"
              name="legal"
              v-model="register.legal"
              @keydown="submittedRegister = false"
            />
            <label for="legal">
              {{ $t('auth.accept') }}
              <RouterLink to="/legal" target="_blank">
                {{ $t('auth.legal') }}*
              </RouterLink>
            </label>
            <div class="checkbox-input" />
            <div
              class="error"
              v-if="submittedRegister && !$v.register.legal.required"
            >
              {{ $t('errors.required') }}
            </div>
          </div>
          <p class="sub">* {{ $t('auth.required-inputs') }}</p>
          <button class="primary-btn" @click="registerUser">
            {{ $t('auth.register') }}
          </button>
        </form>
      </div>
    </div>
    <SweetModal ref="error" overlay-theme="dark" icon="error">
      {{ registerErrorMsg }}
    </SweetModal>
    <SweetModal ref="forgot" overlay-theme="dark" class="forgot">
      <div v-if="!sendNewPass">
        <h3>{{ $t('auth.enter-email') }}</h3>
        <form @submit.prevent>
          <div class="input-container">
            <label for="email">{{ $t('auth.email') }}</label>
            <input
              type="email"
              name="user"
              v-model="forgot.email"
              @keydown="
                submittedForgot = false
                nouser = false
              "
            />
            <div
              class="error"
              v-if="submittedForgot && !$v.forgot.email.required"
            >
              {{ $t('errors.required') }}
            </div>
            <div class="error" v-if="submittedForgot && !$v.forgot.email.email">
              {{ $t('errors.email') }}
            </div>
            <div class="error" v-if="nouser">
              {{ $t('errors.nouser') }}
            </div>
          </div>
          <button class="primary-btn" type="button" @click="sendForgot">
            {{ $t('common.send') }}
          </button>
        </form>
      </div>
      <div v-else>
        <h2>{{ $t('auth.send-newpass') }}</h2>
      </div>
    </SweetModal>
  </div>
</template>

<script>
import api from '../../services/api.service.js'
import { mapMutations } from 'vuex'
import { required, email, sameAs, minLength } from 'vuelidate/lib/validators'
import utilMixins from '@/mixins/utilMixins'
export default {
  name: 'Login',
  mixins: [utilMixins],
  data() {
    return {
      login: {
        email: '',
        password: ''
      },
      register: {
        name: '',
        email: '',
        password: '',
        institutionCode: '',
        legal: ''
      },
      forgot: {
        email: ''
      },
      rePassword: '',
      submittedLogin: false,
      submittedRegister: false,
      submittedForgot: false,
      wrongpass: false,
      registerErrorMsg: this.$t('errors.general'),
      redirect: false,
      nouser: false,
      sendNewPass: false
    }
  },
  async created() {
    if (this.$route.query.redirect) {
      this.redirect = true
    }
  },
  methods: {
    ...mapMutations(['setToken', 'setStatus']),
    async sendUser() {
      this.submittedLogin = true
      this.$v.login.$touch()
      if (this.$v.login.$invalid) {
        return false
      } else {
        const res = await api.post.login(this.login)
        if (res.data.status === '1') {
          this.setToken(res.data.token)
          this.setStatus(1)
          if (this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect)
          } else {
            this.$router.push({ name: 'Home' })
          }
        } else if (res.data.status === '-2') {
          this.wrongpass = true
        } else if (res.data.status === '-1') {
          this.nouser = true
        }
      }
    },
    async registerUser() {
      console.log(this.register)
      this.submittedRegister = true
      this.$v.register.$touch()
      if (this.$v.register.$invalid) {
        return false
      } else {
        const res = await api.post.register(this.register)
        if (res.data.status === '1') {
          this.$router.push({ name: 'Activate' })
        } else {
          if (res.data.status === '-2') {
            this.registerErrorMsg = this.$t('errors.user-exists')
          }
          if (res.data.status === '-1') {
            this.registerErrorMsg = this.$t('errors.password-short')
          }
          this.modalMessage('error')
        }
      }
    },
    showForgotPass() {
      this.$refs.forgot.open()
    },
    async sendForgot() {
      this.submittedForgot = true
      this.$v.forgot.$touch()
      if (this.$v.forgot.$invalid) {
        return false
      } else {
        const res = await api.post.recovery(this.forgot)
        console.log(res)
        if (res.data.status === '1') {
          this.sendNewPass = true
          setTimeout(() => this.$refs.forgot.close(), 3000)
          setTimeout(() => {
            this.sendNewPass = false
            this.forgot.email = ''
          }, 3500)
        } else if (res.data.status === '-1') {
          this.nouser = true
        }
      }
    }
  },
  validations: {
    login: {
      email: {
        required,
        email
      },
      password: {
        required
      }
    },
    forgot: {
      email: {
        required,
        email
      }
    },
    register: {
      name: {
        required
      },
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(8)
      },
      rePassword: {
        required,
        sameAs: sameAs('password')
      },
      legal: {
        required
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content-forbidden {
  max-width: 1200px;
  margin: auto;
  width: 100%;
  @include flex(center, false, true);
  padding: 30px 40px;
  border: 1px solid white;
  margin-bottom: 20px;
  h2 {
    font-size: 34px;
    color: white;
  }
  p {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-bottom: 30px;
  }
  button {
    font-size: 18px;
  }
}
.forgot {
  align-self: flex-start;
  color: $textColor;
  cursor: pointer;
  margin-bottom: 20px;
  &:hover {
    color: white;
  }
  .sweet-modal {
    background: black;
  }
}
.login {
  max-width: 1200px;
  margin: auto;
  .sub {
    margin: 0px;
    font-size: 11px;
    text-align: left;
    align-self: start;
  }
  .content {
    @include grid(2, 40px);
    width: 100%;
    @media (max-width: 830px) {
      display: block;
    }
    .block {
      form {
        border: 1px solid white;
        padding: 20px;
        @include flex(start, false, column);
        height: fit-content;
        button {
          justify-self: end;
        }
      }
    }
  }
  .input-container {
    @include flex(center, flex-start, column);
    margin-bottom: 12px;
    width: 100%;
    label {
      color: $textColor;
      margin-bottom: 6px;
    }
    input {
      background: $darkGrey;
      border: 0px;
      padding: 8px 4px;
      width: 100%;
      color: #fff;
    }
    select {
      background: $darkGrey;
      border: 0px;
      padding: 8px 4px;
      width: 100%;
      color: #fff;
      border-radius: 0px;
    }
    .sub {
      margin: 0px;
      font-size: 11px;
    }
    &.checkbox {
      flex-direction: row;
      align-items: start;
      position: relative;
      justify-content: flex-start;
      gap: 10px;
      .checkbox-input {
        width: 20px;
        height: 20px;
        border: 1px solid white;
        position: absolute;
        left: 0;
        display: flex;
      }
      input {
        position: relative;
        width: 20px;
        height: 20px;
        opacity: 0;
        z-index: 20;
      }
      input:checked ~ .checkbox-input:after {
        content: ' \2713';
        color: white;
        font-size: 16px;
        margin: auto;
      }
    }
  }
}
</style>
