<template>
  <div class="SelectLanguage">
    <div class="dropdown-language">
      <BDropdown v-model="isPublic" aria-role="list" class="sel-lang">
        <template v-if="isPublic" #trigger>
          <BButton>
            <img
              class="rounded-flag"
              :src="getImgUrl(activeLanguage.id)"
              v-bind:alt="activeLanguage.language"
              width="20px"
              height="20px"
            />
            <span class="tit">{{ activeLanguage.language }}</span>
            <BIcon pack="fas" icon="sort-down" class="sort-arrow" />
          </BButton>
        </template>
        <BDropdownItem
          :value="true"
          aria-role="listitem"
          v-for="(language, index) in languages"
          :key="index"
          @click="changeLanguage(language.id)"
          :id="language.id"
        >
          <div class="media">
            <img
              class="rounded-flag"
              :src="getImgUrl(language.id)"
              v-bind:alt="language.language"
              width="20px"
              height="20px"
            />
            <div class="media-content">
              <h3>{{ language.language }}</h3>
            </div>
          </div>
        </BDropdownItem>
      </BDropdown>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectLanguage',
  data() {
    return {
      language: 'esp',
      isPublic: true,
      languages: [
        {
          id: 'esp',
          language: 'ESP'
        },
        {
          id: 'eng',
          language: 'ENG'
        },
        {
          id: 'val',
          language: 'VAL'
        }
      ]
    }
  },
  mounted() {},
  computed: {
    activeLanguage() {
      return this.languages.find(elem => elem.id === this.$i18n.locale)
    }
  },
  methods: {
    changeLanguage(id) {
      this.$i18n.locale = id
      this.$parent.language = this.language
    },
    getImgUrl(pic) {
      return require('./assets/' + pic + '.png')
    }
  }
}
</script>

<style lang="scss">
.SelectLanguage {
  z-index: 1000;
  .dropdown-language {
    z-index: 800;
    .dropdown {
      z-index: 1000;
      .dropdown-trigger {
        .button {
          cursor: pointer;
          background: $primary;
          padding: 0px;
          border-style: none;
          border-radius: 5px;
          height: auto;
          span,
          .tit {
            color: white !important;
          }
          img {
            border-radius: 100%;
            margin-right: 5px;
          }
        }
      }
      .dropdown-menu {
        z-index: 1000;
        border-radius: 5px;
        left: -10px;
        .dropdown-content {
          background-color: #000 !important;
          padding: 5px;
          .dropdown-item {
            background: #000;
            padding: 0;
            width: 100px;
            .media {
              //border: 0.5px solid white;
              padding: 0px 7px;
              cursor: pointer;
              @include flex();
              background-color: $primary;
              justify-content: space-evenly;
              &:hover {
                .media-content {
                  h3 {
                    font-weight: 500;
                  }
                }
              }
              img {
                border-radius: 100%;
              }
              .media-content {
                h3 {
                  margin: 8px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 845px) {
  .SelectLanguage {
    margin: 0px;
    .dropdown-language {
      .dropdown {
        .dropdown-trigger {
          .button {
            padding: 8px;
            background: #3f3f41;
          }
        }
        .dropdown-menu {
          .dropdown-content {
            .dropdown-item {
              .media {
                .media-content {
                  h3 {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
