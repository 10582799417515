<template>
  <div class="legal">
    <h1>{{ $t('legal.title') }}</h1>
    <h3>{{ $t('legal.responsable-data') }}</h3>
    <ul>
      <li>{{ $t('legal.identity') }}: Poética Dos Punto Cero SCP</li>
      <li>CIF: J98525470</li>
      <li>{{ $t('legal.address') }}: Avda. Peset Aleixandre, 1-20</li>
      <li>{{ $t('legal.email') }}: info@poetica2puntocero.com</li>
    </ul>
    <h3>{{ $t('legal.purpose') }}</h3>
    <p>{{ $t('legal.purpose-1') }}</p>
    <p>{{ $t('legal.purpose-2') }}</p>
    <h3>{{ $t('legal.legitimation') }}</h3>
    <p>{{ $t('legal.legitimation-1') }}</p>
    <h3>{{ $t('legal.how') }}</h3>
    <p>{{ $t('legal.how-1') }}</p>
    <h3>{{ $t('legal.how-long') }}</h3>
    <p>{{ $t('legal.how-long-1') }}</p>
    <p>{{ $t('legal.how-long-2') }}</p>
    <p>{{ $t('legal.how-long-3') }}</p>
    <h3>{{ $t('legal.rights') }}</h3>
    <p>{{ $t('legal.rights-1') }}</p>
    <h3>{{ $t('legal.channel') }}</h3>
    <p>{{ $t('legal.channel-1') }}</p>
  </div>
</template>

<script>
export default {
  name: 'Legal',
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
h1 {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 20px;
}
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}
</style>
