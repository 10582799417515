<template>
  <div class="video-player">
    <div class="poem-container" v-if="poem != ''">
      <div class="video-container">
        <VideoPlayer
          :videoId="poem.video"
          @ended="nextVideo"
          @play="isplaying = true"
          @pause="isplaying = false"
          :key="poem.id"
        />
      </div>
      <h3>
        <RouterLink :to="`/detail/poet/${poem.poet}`" class="poet">
          {{ poem.poet_name }}
        </RouterLink>
        | {{ poem.title }} |
        <span v-if="poem.actor != 65">
          <RouterLink :to="`/detail/actor/${poem.actor}`">
            {{ poem.actor_name }}
          </RouterLink>
        </span>
      </h3>
      <textarea v-model="this.poem.text" readonly />
    </div>
    <div class="thumbnails-row">
      <!-- Current video thumbnail -->
      <div class="thumbnail actual" v-if="poem != ''">
        <div class="thumbnail-img">
          <img :src="imgUrlXS + poem.image" />
        </div>
        <div class="thumbnail-info">
          <p class="upper">{{ $t('video.watching') }}</p>
          <h4 class="thumbnail-title">{{ poem.title }}</h4>
        </div>
      </div>
      <div class="divider actual" />
      <!-- Video list -->
      <div class="autoplay">
        <span class="tit">{{ $t('video.next') }}</span>
        <BSwitch type="is-success" v-model="playNext">
          {{ $t('video.autoplay') }}
        </BSwitch>
      </div>
      <div class="video-list">
        <div :key="elem.id" v-for="elem in suggestions" class="thumbnail">
          <div class="thumbnail-img" @click="goToPoem(elem.id)">
            <img :src="imgUrlXS + elem.image" />
          </div>
          <div class="thumbnail-info">
            <h3 class="thumbnail-title" @click="goToPoem(elem.id)">
              {{ elem.title }}
            </h3>
            <RouterLink :to="`/detail/poet/${elem.poet}`">
              <h4 class="actor-thumbnail poet-thumbnail">
                {{ elem.poet_name }}
              </h4>
            </RouterLink>
            <RouterLink
              v-if="elem.actor != 65"
              :to="`/detail/actor/${elem.actor}`"
            >
              <h4 class="actor-thumbnail">
                {{ elem.actor_name }}
              </h4>
            </RouterLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/services/api.service.js'
import VideoPlayer from '@/components/VideoPlayer'
export default {
  name: 'VideoDetail',
  components: { VideoPlayer },
  data() {
    return {
      videosApi: [],
      poem: '',
      collection: false,
      suggestions: [],
      playNext: true,
      isplaying: false,
      imgUrl: process.env.VUE_APP_IMG_URL,
      imgUrlXS: process.env.VUE_APP_EXTRA_SMALL_IMG_URL,
      imgUrlS: process.env.VUE_APP_SMALL_IMG_URL,
      imgUrlM: process.env.VUE_APP_MEDIUM_IMG_URL,
      imgUrlL: process.env.VUE_APP_LARGE_IMG_URL
    }
  },
  created() {
    if (this.$route.query.collection) {
      this.collection = this.$route.query.collection
    }
  },
  async mounted() {
    await this.getMainPoem()
    window.onscroll = this.checkVideo
  },
  watch: {
    $route() {
      this.getMainPoem()
      window.onscroll = this.checkVideo
    }
  },
  methods: {
    async getMainPoem() {
      const res = await api.get.mainPoem(this.$route.params.poemId)
      const poem = res.data.poem
      const poet = await this.getPoet(poem.poet)
      const actor = await this.getActor(poem.actor)
      poem.poet_name = poet
      poem.actor_name = actor
      this.poem = poem
      this.getSuggestions()
    },
    async getPoem(id) {
      const res = await api.get.poem(id)
      const poem = res.data.poem
      const poet = await this.getPoet(poem.poet)
      const actor = await this.getActor(poem.actor)
      poem.poet_name = poet
      poem.actor_name = actor
      return poem
    },
    async getPoet(poetId) {
      const res = await api.get.poet(poetId)
      const poet = res.data.poet.name

      return poet
    },
    async getActor(actorId) {
      const res = await api.get.actor(actorId)
      const actor = res.data.actor.name
      return actor
    },
    nextVideo() {
      if (this.playNext) {
        const next = this.suggestions[0].id
        this.goToPoem(next)
      }
    },
    checkVideo() {
      const videoContainer = document.querySelector('.video-container')
      if (videoContainer) {
        const bottomVideo =
          videoContainer.offsetTop + videoContainer.offsetHeight
        if (scrollY >= bottomVideo && this.isplaying) {
          document.querySelector('.video').classList.add('miniature')
        } else {
          document.querySelector('.video').classList.remove('miniature')
        }
      }
    },
    goToPoem(id) {
      if (this.collection) {
        this.$router.push(`/poem/${id}?collection=${this.collection}`)
      } else {
        this.$router.push(`/poem/${id}`)
      }
    },
    async getSuggestions() {
      this.suggestions = []
      if (this.collection) {
        const res = await api.get.collection(this.collection)
        const poems = res.data.collection.poems
        poems.sort(function(a, b) {
          return a - b
        })
        let n = 0
        for (const id of poems) {
          if (id != this.poem.id) {
            const poem = await this.getPoem(id)
            if (id < this.poem.id) {
              this.suggestions.push(poem)
            } else if (id > this.poem.id) {
              this.suggestions.splice(n, 0, poem)
              n++
            }
          }
        }
      } else {
        const res = await api.get.suggestions(this.$route.params.poemId)
        const poems = res.data.suggestions
        for (const id of poems) {
          if (id != this.poem.id) {
            const poem = await this.getPoem(id)
            this.suggestions.push(poem)
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.autoplay {
  @include flex;
  margin-bottom: 20px;
  .switch {
    margin-left: 5px;
  }
}
.thumbnail {
  cursor: pointer;
  display: grid;
  grid-template-columns: 150px auto;
  margin-bottom: 10px;
  .thumbnail-title {
    margin: 5px 0px;
    text-transform: uppercase;
  }
  .actor-thumbnail {
    font-weight: 400;
    margin: 5px 0px;
    color: $textColor;
    font-size: 14px;
  }
  .poet-thumbnail {
    text-transform: uppercase;
  }
}
.thumbnail img {
  width: 150px;
  height: 105px;
  border: 1px solid $textColor;
  object-fit: cover;
}

.thumbnail-info {
  margin-left: 20px;
  .upper {
    font-weight: 400;
  }
}

.thumbnail h3 {
  font-size: 16px;
}
.actual {
  @media (max-width: 1200px) {
    display: none;
  }
}
h3,
p {
  margin: 0;
  padding: 0;
}

.thumbnail-views {
  font-size: 14px;
}
.video-player {
  display: flex;
  width: 90%;
  margin: auto;
  //padding-top: 50px;
  justify-content: center;
  .poem-container {
    .video-container {
      margin-bottom: 20px;
      width: 1000px;
      height: 550px;
      padding: 5px;
      .vimeo-player {
        width: 750px;
        height: 400px;
      }
      .miniature {
        position: fixed;
        width: 300px;
        height: fit-content;
        bottom: 20px;
        right: 20px;
        border: 2px solid white;
        background: black;
        z-index: 3;
      }
    }
    h3 {
      text-transform: uppercase;
      font-weight: 400;
    }
    .poet {
      font-weight: 600;
    }
    //margin-right: 40px;
    h3,
    p,
    textarea,
    h5 {
      width: fit-content;
      padding-left: 15px;
    }
    textarea {
      background-color: $primary;
      color: white;
      resize: none;
      border: none;
      width: 600px;
      height: 700px;
      overflow-y: auto;
      padding-top: 10px;
      font-size: 15px;
      line-height: 1.6;
      letter-spacing: 0.5px;
      font-family: $main-font;
      &:focus {
        outline: none;
      }
    }
  }
}
.autoplay {
  .tit {
    color: white;
    text-transform: uppercase;
  }
}
.row {
  display: flex;
  justify-content: space-between;
}
.switch input[type='checkbox']:checked + .check {
  background: green;
}
button {
  background: #d0021b;
  color: white;
  border: none;
  padding: 10px 20px;
}

.video-list {
  height: calc(100vh - 100px);
  overflow-y: scroll;
}
.divider {
  height: 1px;
  width: 100%;
  background-color: $textColor;
  margin: 20px 0px;
}

//Scrollbar
::-webkit-scrollbar {
  width: 1.5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #888;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #ddd;
}
@media screen and (max-width: 1350px) {
  .video-player {
    .poem-container {
      .video-container {
        width: 850px;
        height: auto;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .video-player {
    width: auto;
    @include flex();
    flex-direction: column;
    .poem-container {
      width: 80%;
      margin: 0px;
      @media (max-width: 750px) {
        width: 100%;
      }
      .video-container {
        width: 100%;
        height: auto;
        @media screen and (max-width: 1100px) {
          height: auto;
        }
        @media screen and (max-width: 1000px) {
          height: auto;
        }
      }
      .video {
        width: 100%;
      }
      textarea {
        height: 300px;
        overflow: auto;
        width: 100%;
        margin-bottom: 50px;
      }
    }
  }
}
@media screen and (max-width: 800px) {
  .video-player {
    .poem-container {
      .video-container {
        height: auto;
      }
      .video {
        height: auto;
      }
    }
  }
}
@media screen and (max-width: 620px) {
  .video-player {
    .poem-container {
      .video-container {
        height: auto;
      }
      .video {
        height: auto;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .video-player {
    .poem-container {
      .video-container {
        height: auto;
      }
      .video {
        height: auto;
      }
    }
  }
}
@import '/../../../buefy/dist/buefy.css';
</style>
