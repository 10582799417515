<template>
  <div class="thanks">
    <h2>{{ $t('thanks.title') }}</h2>
    <p>{{ $t('thanks.intro') }}</p>
    <ul>
      <li v-for="(patron, index) in patrons" :key="index">
        {{ patron }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Thanks',
  data() {
    return {
      patrons: [
        'Victoria Heitzmann',
        'Alba Ada',
        'Eva Llopis',
        'Rodolfo Bonifaz',
        'Manuel Jurado',
        'Ángelo Néstore',
        'Luisferlomo',
        'Bego Soler',
        'Laura Lozano Marín',
        'Orihuel',
        'Laura Olleo',
        'Ramondelclot',
        'Jose Vicente K',
        'Silvia Alabort',
        'Manu Barrieras',
        'Sara Salgado',
        'Maria Eugenia Bohigues',
        'Lluís Roda',
        'Maregra',
        'Maite Mateo',
        'Sati',
        'M. Carmen López Giménez',
        'Korgen',
        'alexdarriba',
        'Cal Centelles',
        'Fernando Torres Pérez',
        'Antonio José Herrero',
        'Geles Bello',
        'Alberto Sancho',
        'Martín Orzanova',
        'Jordi Villanueva',
        'Vicente Sanchis',
        'Sandra Duky',
        'Carlos Vidal',
        'Olga López-Romero',
        'jenifer_Cc',
        'Mila Villanueva',
        'Lucía Luciluz',
        'Rosa Balcells',
        'Robert Archer',
        'Jirurzun',
        'Susana Oliva Vargas',
        'María José Sánchez Sánchez',
        'Piedad Bonnett',
        'Herma',
        'Versonautas',
        'Antonio M. Castellano Suárez',
        'Jorge Acampana',
        'José Luis Domingo',
        'Mercedes Tortosa',
        'Violeta Sáez',
        'Pepe Santatecla',
        'JesusGe',
        'Paula Trabuchelli',
        'Ana Noguera',
        'Carafa',
        'Francisco Serrano',
        'Eva Benedito',
        'Oscar Gordillo',
        'Ángel Fondo',
        'Martín Palomo',
        'Ana Ovando',
        'Félix Carbó',
        'Chus Martín Vicente',
        'María Beja',
        'Araceli Fernández Ojalvo',
        'Mónica Robredo López de Maturana',
        'Clara Aura',
        'Manuel Delgado Benavides',
        'Elena Torres (Poeta)',
        'Ana Castro',
        'Alex Penadés',
        'Adela Salguero',
        'Fernando Diaz C',
        'Cristóbal Aznar (profesor de secundaria)',
        'Elena Roig Sanchis',
        'Víktor Gómez Ferrer',
        'Margherita De Stradis',
        'Aurora Garrido',
        'Isabel Lopez Cabrera',
        'Alba Cardeña Serrano',
        'Raquel Moreno',
        'María José Bustos Paredes',
        'Isabel Nalda Page',
        'Marco Mack',
        'Enrique Hinojosa Baca - Ala y Raíz',
        'Rus Moreno',
        'Pilar Gómez',
        'Marta Cabalelro',
        'Sandra',
        'Bibiana Collado Cabrera',
        'Manuel Díaz García ',
        'Josevi Bosch',
        'Rosa Pastor Simón',
        'Mayte Santosjuanes Sebastià',
        'Luis Arrizabalaga',
        'Juanfra Álvarez',
        'Pepe Monasterio',
        'José Manuel Lucía Megías, catedrático y escritor',
        'Pablo Muñoz Regadera ',
        'Miguel Ángel Muñoz Quintana',
        'Soraya Gómiz de Serranos',
        'María Luisa Caride',
        'Rosana García Zapico',
        'Fernando de Bonrostro ',
        'Silvia Gómiz Pérez ',
        'Marisa Larulla',
        'Juan Luis Bedins. Poeta y presidente de la Asociación Valenciana de Escritores y Críticos Literarios (CLAVE).',
        'Rafael Soler',
        'Toni Alcolea',
        'Sol de Diego'
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.thanks {
  @include flex($direction: column);
  p {
    max-width: 700px;
    text-align: center;
    margin: 20px;
  }
  ul {
    max-width: 700px;
    text-align: center;
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    @media (max-width: 750px) {
      columns: 1;
      -webkit-columns: 1;
      -moz-columns: 1;
    }
  }
}
</style>
