<template>
  <div class="users">
    <!-- activos -->
    <template v-if="users.length > 0">
      <h2>{{ $t('profile.active-students') }}</h2>
      <!-- <div class="search-bar">
        <input type="text" :placeholder="$t('common.search')" />
        <button class="black-btn" @click="$refs.addCode.open()">
          <span>+</span>
          {{ $t('profile.add-student') }}
        </button>
      </div> -->
      <table class="students-list">
        <thead>
          <tr class="header">
            <th>{{ $t('common.name') }}</th>
            <th>{{ $t('common.email') }}</th>
            <th>{{ $t('common.code') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(user, index) in users"
            :key="index"
            :class="isEven(index)"
          >
            <td>{{ user.uname }}</td>
            <td>{{ user.uemail }}</td>
            <td>{{ user.ucode }}</td>
          </tr>
        </tbody>
      </table>
    </template>
    <!-- aceptados -->
    <template v-if="accepted.length > 0">
      <h2>{{ $t('profile.accepted') }}</h2>
      <table class="students-list">
        <thead>
          <tr class="header">
            <th>{{ $t('common.name') }}</th>
            <th>{{ $t('common.email') }}</th>
            <th>{{ $t('common.code') }}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(user, index) in accepted"
            :key="index"
            :class="isEven(index)"
          >
            <td>{{ user.name }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.institutionCode }}</td>
            <td>
              <button class="secondary-btn" @click="reject(user)">
                {{ $t('profile.reject') }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <!-- rechazados -->
    <template v-if="rejected.length > 0">
      <h2>{{ $t('profile.rejected') }}</h2>
      <table class="students-list">
        <thead>
          <tr class="header">
            <th>{{ $t('common.name') }}</th>
            <th>{{ $t('common.email') }}</th>
            <th>{{ $t('common.code') }}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(user, index) in rejected"
            :key="index"
            :class="isEven(index)"
          >
            <td>{{ user.name }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.institutionCode }}</td>
            <td>
              <button class="secondary-btn" @click="accept(user)">
                {{ $t('profile.accept') }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <!-- en espera -->
    <template v-if="pending.length > 0">
      <h2>{{ $t('profile.waiting-approval') }}</h2>
      <table class="students-list">
        <thead>
          <tr class="header">
            <th>{{ $t('common.name') }}</th>
            <th>{{ $t('common.email') }}</th>
            <th>{{ $t('common.code') }}</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(user, index) in pending"
            :key="index"
            :class="isEven(index)"
          >
            <td>{{ user.name }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.institutionCode }}</td>
            <td>
              <button class="secondary-btn" @click="accept(user)">
                {{ $t('profile.accept') }}
              </button>
            </td>
            <td>
              <button class="secondary-btn" @click="reject(user)">
                {{ $t('profile.reject') }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <SweetModal
      :title="$t('profile.add-code')"
      ref="addCode"
      overlay-theme="dark"
    >
      Añadir codígo
    </SweetModal>
    <SweetModal
      :title="$t('profile.edit-code')"
      ref="editCode"
      overlay-theme="dark"
    >
      editar código
    </SweetModal>
  </div>
</template>

<script>
import api from '@/services/api.service.js'
export default {
  name: 'Users',
  data() {
    return {
      users: [],
      pending: [],
      accepted: [],
      rejected: []
    }
  },
  created() {
    this.getUsers()
    this.getPending()
    this.getAccepted()
    this.getRejected()
  },
  methods: {
    isEven(index) {
      if (index % 2 === 0) {
        return 'grey'
      } else {
        return ''
      }
    },
    async getUsers() {
      const res = await api.get.institutionUsers()
      console.log(res)
      this.users = res.data.users
    },
    async getPending() {
      const res = await api.get.pendingUsers()
      this.pending = res.data.users
    },
    async getAccepted() {
      const res = await api.get.acceptedUsers()
      this.accepted = res.data.users
    },
    async getRejected() {
      const res = await api.get.rejectedUsers()
      this.rejected = res.data.users
    },
    async accept(data) {
      console.log(data)
      const user = { institutionCode: data.institutionCode, userId: data.id }
      await api.post.acceptUser(user)
      this.getPending()
      this.getAccepted()
      this.getRejected()
    },
    async reject(data) {
      const user = { institutionCode: data.institutionCode, userId: data.id }
      await api.post.rejectUser(user)
      this.getPending()
      this.getAccepted()
      this.getRejected()
    }
  }
}
</script>
<style lang="scss" scoped>
.users {
  .search-bar {
    @include flex(space-between);
    input {
      background: #000;
      border: 0.5px solid #fff;
      color: #fff;
      padding: 15px 6px;
      height: 45px;
      width: 400px;
      background-size: 20px;
      padding-left: 24px;
      background-position: 4px 13px;
      background-repeat: no-repeat;
      background-image: url(../../../assets/icons/search.png);
    }
  }
  table {
    padding: 0px;
    list-style-type: none;
    border: 1px solid #fff;
    th {
      color: white;
      font-weight: bold;
      border: 0px;
    }
    tr {
      padding: 6px 12px;
      color: $textColor;
    }
    tbody {
      tr {
        &.grey {
          background: $darkGrey;
        }
        &:hover {
          cursor: pointer;
          background: #fff;
          transition: 0.2 all ease-in-out;
          .secondary-btn {
            border: 1px solid black;
          }
        }
        .secondary-btn {
          border: 1px solid;
        }
        td {
          vertical-align: middle;
        }
      }
    }
  }
}
</style>
