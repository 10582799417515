<template>
  <div class="new-pass">
    <h1>{{ $t('common.new-password') }}</h1>
    <form @submit.prevent>
      <div class="input-container">
        <label for="email">{{ $t('auth.email') }}</label>
        <input
          type="email"
          name="user"
          v-model="email"
          @keydown="submitted = false"
        />
        <div class="error" v-if="submitted && !$v.email.required">
          {{ $t('errors.required') }}
        </div>
        <div class="error" v-if="submitted && !$v.email.email">
          {{ $t('errors.email') }}
        </div>
      </div>
      <div class="input-container">
        <label for="code">{{ $t('auth.code') }}</label>
        <input
          type="text"
          name="user"
          v-model="code"
          @keydown="submitted = false"
        />
        <div class="error" v-if="submitted && !$v.code.required">
          {{ $t('errors.required') }}
        </div>
      </div>
      <div class="input-container">
        <label for="password">{{ $t('auth.password') }}*</label>
        <input
          type="password"
          name="password"
          v-model="password"
          @keydown="submitted = false"
        />
        <p class="sub">{{ $t('auth.min-length') }}</p>
        <div class="error" v-if="submitted && !$v.password.required">
          {{ $t('errors.required') }}
        </div>
        <div class="error" v-if="submitted && !$v.password.minLength">
          {{ $t('errors.password-short') }}
        </div>
      </div>
      <div class="input-container">
        <label for="repassword">{{ $t('auth.re-password') }}*</label>
        <input
          type="password"
          name="repassword"
          v-model="rePassword"
          @keydown="submitted = false"
        />
        <div class="error" v-if="submitted && !$v.rePassword.required">
          {{ $t('errors.required') }}
        </div>
        <div class="error" v-if="submitted && !$v.rePassword.sameAs">
          {{ $t('errors.sameAs') }}
        </div>
      </div>
      <button class="primary-btn" type="button" @click="sendPassword">
        {{ $t('common.send') }}
      </button>
    </form>
    <SweetModal icon="success" ref="success">
      <h2 style="color:black">{{ $t('auth.password-changed') }}</h2>
      <br />
      <RouterLink to="/login" class="primary-btn">
        {{ $t('auth.login') }}
      </RouterLink>
    </SweetModal>
    <SweetModal icon="error" ref="error">
      <h2 style="color:black">{{ $t('error.general') }}</h2>
    </SweetModal>
  </div>
</template>

<script>
import { required, sameAs, minLength, email } from 'vuelidate/lib/validators'
import api from '@/services/api.service.js'
export default {
  name: 'NewPassword',
  data() {
    return {
      password: '',
      rePassword: '',
      submitted: false,
      code: '',
      email: ''
    }
  },
  methods: {
    async sendPassword() {
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        return false
      } else {
        const newPass = {
          email: this.email,
          code: this.code,
          password: this.password
        }
        const res = await api.post.newpassword(newPass)
        console.log(res)
        if (res.data.status === '1') {
          this.$refs.success.open()
        } else {
          this.$refs.error.open()
        }
      }
    }
  },
  validations: {
    code: {
      required
    },
    email: {
      required,
      email
    },
    password: {
      required,
      minLength: minLength(8)
    },
    rePassword: {
      required,
      sameAs: sameAs('password')
    }
  }
}
</script>

<style lang="scss" scoped>
.new-pass {
  max-width: 1200px;
  padding: 30px;
}
.input-container {
  @include flex(center, flex-start, column);
  margin-bottom: 12px;
  width: 100%;
  label {
    color: $textColor;
    margin-bottom: 6px;
  }
  input {
    background: $darkGrey;
    border: 0px;
    padding: 8px 4px;
    width: 100%;
    color: #fff;
    width: 250px;
  }
  select {
    background: $darkGrey;
    border: 0px;
    padding: 8px 4px;
    width: 100%;
    color: #fff;
    border-radius: 0px;
  }
  .sub {
    margin: 0px;
    font-size: 11px;
  }
}
</style>
