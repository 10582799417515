<template>
  <div class="profile">
    <div class="content">
      <h1>{{ $t('profile.my-profile') }}</h1>
      <div
        class="buttons-bar"
        v-if="!(user.profile == 0 && userInstitution != '')"
      >
        <button
          v-for="(but, index) in profileTypes"
          :key="index"
          :class="activeProfile === but ? 'primary-btn' : 'black-btn'"
          @click="activeProfile = but"
        >
          {{ $t(`profile.${but}`) }}
        </button>
      </div>
      <div class="profile-box">
        <PersonalProfile v-if="activeProfile === 'personal'" />
        <InstitutionProfile v-if="activeProfile === 'institution'" />
      </div>
    </div>
  </div>
</template>
<script>
import PersonalProfile from '../components/profile/PersonalProfile'
import InstitutionProfile from '../components/profile/InstitutionProfile'
import api from '../services/api.service.js'
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'Profile',
  components: { PersonalProfile, InstitutionProfile },
  data() {
    return {
      activeProfile: 'personal',
      profileTypes: ['personal', 'institution']
    }
  },
  computed: {
    ...mapState(['token', 'user', 'userInstitution'])
  },
  async created() {
    const res = await api.get.user()
    this.setUser(res.data.user)
    const res2 = await api.get.userInstitution()
    if (res2.data.status == 1) {
      this.setUserInstitution(res2.data.insitution)
    }
  },
  methods: {
    ...mapMutations(['setUser', 'setUserInstitution']),
    checkProfile(val) {
      if (this.activeProfile === val) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.profile {
  padding: 20px;
  width: 100%;
  margin: auto;
  h1 {
    text-transform: uppercase;
  }
  .buttons-bar {
    @include flex(start);
    width: 100%;
    button {
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      padding: 9px 15px;
      margin-right: 15px;
      font-size: 16px;
    }
  }
  @media (min-width: 769px) {
    max-width: 760px;
    margin: auto;
  }
}
</style>
