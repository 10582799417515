import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'

Vue.use(Vuex)
const secureLocalStorage = new SecureLS({
  encodingType: 'aes',
  isCompression: true
})
export const store = new Vuex.Store({
  state: {
    token: '',
    status: 0,
    user: '',
    userInstitution: ''
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload
    },
    setStatus(state, payload) {
      state.status = payload
    },
    setUser(state, payload) {
      state.user = payload
    },
    setUserInstitution(state, payload) {
      state.userInstitution = payload
    }
  },
  actions: {},
  getters: {
    getToken: state => state.token,
    getStatus: state => state.status
  },
  modules: {},
  plugins: [
    createPersistedState({
      key: 'pR45jjn8G',
      storage: {
        getItem: key => secureLocalStorage.get(key),
        setItem: (key, value) => secureLocalStorage.set(key, value),
        removeItem: key => secureLocalStorage.remove(key)
      }
    })
  ]
})
