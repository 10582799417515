<template>
  <div class="personal">
    <form>
      <div class="input-container">
        <label for="name">{{ $t('common.name') }}</label>
        <input type="text" name="name" v-model="user.name" />
        <div class="error" v-if="submitted && !$v.user.name.required">
          {{ $t('errors.required') }}
        </div>
      </div>
      <div class="input-container">
        <label for="email">{{ $t('common.email') }}</label>
        <input
          type="email"
          name="email"
          v-model="user.email"
          @keydown="submitted = false"
        />
        <div class="error" v-if="submitted && !$v.user.email.required">
          {{ $t('errors.required') }}
        </div>
        <div class="error" v-if="submitted && !$v.user.email.email">
          {{ $t('errors.email') }}
        </div>
      </div>
      <div class="input-container">
        <label for="password">{{ $t('common.new-password') }}</label>
        <input
          type="password"
          name="password"
          v-model="password"
          @keydown="submitted = false"
        />
      </div>
      <div class="input-container">
        <label for="re-password">{{ $t('auth.re-password') }}</label>
        <input
          type="password"
          name="re-password"
          v-model="rePassword"
          @keydown="submitted = false"
        />
        <div class="error" v-if="submitted && !$v.rePassword.sameAs">
          {{ $t('errors.sameAs') }}
        </div>
      </div>
      <div class="input-container">
        <label for="name">{{ $t('profile.pass-type') }}</label>
        <input
          type="text"
          name="pass"
          :value="passOptions[user.pass]"
          @keydown="submitted = false"
          disabled
        />
      </div>
      <template v-if="userInstitution == ''">
        <template v-if="user.pass != 0">
          <div class="half">
            <div class="input-container">
              <label for="date">{{ $t('profile.pass-expiration') }}</label>
              <input
                type="date"
                name="date"
                :value="user.caducity"
                disabled
                @keydown="submitted = false"
              />
            </div>
            <template v-if="user.profile == 0">
              <a href="linkToShop" target="_blank" class="red-btn">
                {{ $t('profile.renovate-question') }}
              </a>
            </template>
            <template v-if="user.profile == 1">
              <a href="linkToShop" target="_blank" class="red-btn">
                {{ $t('profile.renovate-question') }}
              </a>
            </template>
          </div>
        </template>
        <template v-else>
          <div class="half">
            <div class="input-container">
              <label for="date">{{ $t('profile.pass-expiration') }}</label>
              <input
                type="date"
                name="date"
                :value="user.caducity"
                disabled
                @keydown="submitted = false"
              />
            </div>
            <div class="btn-container">
              <a :href="linkToShop" target="_blank" class="black-btn">
                {{ $t('profile.buy-pass') }}
              </a>
            </div>
          </div>
        </template>
      </template>
      <div class="input-container" v-if="user.profile == 0">
        <label for="code">{{ $t('profile.institution-code') }}</label>
        <input
          type="text"
          name="institutionCode"
          v-model="user.institutionCode"
          @keydown="submitted = false"
        />
      </div>
    </form>
    <button class="save" type="submit" @click="sendUser">
      {{ $t('common.save-changes') }}
    </button>
    <SweetModal ref="changesOk" overlay-theme="dark" icon="success">
      {{ $t('common.changes-saved') }}
    </SweetModal>
  </div>
</template>

<script>
import api from '../../services/api.service.js'
import { mapState, mapMutations } from 'vuex'
import { required, email, sameAs } from 'vuelidate/lib/validators'
import utilMixins from '@/mixins/utilMixins'
export default {
  name: 'PersonalProfile',
  mixins: [utilMixins],
  data() {
    return {
      password: '',
      rePassword: '',
      submitted: false,
      linkToShop: 'https://www.poetica2puntocero.com/categoria-producto/verso/'
    }
  },
  computed: {
    ...mapState(['user', 'userInstitution']),
    passOptions() {
      const opts = {
        0: this.$t('profile.pass-trial'),
        1: this.$t('profile.pass-1month'),
        6: this.$t('profile.pass-6months'),
        12: this.$t('profile.pass-12months'),
        99: this.$t('profile.pass-special')
      }
      return opts
    }
  },
  validations: {
    user: {
      name: {
        required
      },
      email: {
        email,
        required
      }
    },
    rePassword: {
      sameAs: sameAs('password')
    }
  },
  methods: {
    ...mapMutations(['setUser']),
    async sendUser() {
      const userData = {
        name: this.user.name,
        email: this.user.email,
        institutionCode: this.user.institutionCode
      }
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        return false
      } else {
        if (this.password != '') {
          userData.password = this.password
        }
        const res = await api.put.user(userData)
        if (res.data.message === 'OK') {
          this.modalMessage('changesOk')
          const res2 = await api.get.user()
          this.setUser(res2.data.user)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.personal {
  border: 0.5px solid #fff;
  margin-top: 18px;
  padding: 18px;
  @include flex(center, false, true);
  form {
    width: 100%;
    @media (min-width: 769px) {
      @include grid(2, 20px);
    }
  }
  .save {
    background: #fff;
    color: $primary;
    border: 1px solid white;
    border-radius: 0px;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    font-size: 16px;
    padding: 6px 12px;
    transition: 0.2s all ease-in-out;
    &:hover {
      background: black;
      color: white;
      cursor: pointer;
    }
  }
}
.half {
  @include flex(start, flex-start, false);
  .input-container {
    margin-right: 10px;
    width: min-content;
    input {
      width: auto;
    }
  }
  .red-btn {
    padding: 5px !important;
  }
  .btn-container,
  .red-btn {
    a {
      height: 66px;
      @include flex();
    }
  }
}
.input-container {
  @include flex(start, flex-start, column);
  margin-bottom: 12px;
  label {
    color: $textColor;
    margin-bottom: 6px;
  }
  input {
    background: $darkGrey;
    border: 0px;
    padding: 8px 4px;
    width: 100%;
    color: #fff;
  }
  select {
    background: $darkGrey;
    border: 0px;
    padding: 8px 4px;
    width: 100%;
    color: #fff;
    border-radius: 0px;
  }
}
.btn-container {
  @include flex();
  text-align: center;
  a {
    padding: 10px;
  }
}
</style>
