import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Profile from '../views/Profile.vue'
import Login from '../components/auth/Login.vue'
import Activate from '../components/auth/Activate.vue'
import Collection from '../views/Collection.vue'
import Collections from '../views/Collections.vue'
import VideoDetail from '../views/VideoDetail.vue'
import NewPassword from '../views/NewPassword.vue'
import Legal from '../views/Legal.vue'
import Thanks from '../views/Thanks.vue'
import Purchased from '../views/Purchased.vue'
import api from '../services/api.service.js'
import { doesJwtExist, isJwtValid } from '@/middlewares/access.middleware'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: { requiresAuth: true, requiresAnon: true }
  },
  {
    path: '/purchased/',
    name: 'Purchased',
    component: Purchased,
    meta: { requiresAuth: true, requiresAnon: false },
    props: true
  },
  {
    path: '/activate/:code?',
    name: 'Activate',
    component: Activate,
    meta: { requiresAuth: false, requiresAnon: true },
    props: true
  },
  {
    path: '/detail/:detailType/:typeId',
    name: 'Details',
    meta: { requiresAuth: true, requiresAnon: false },
    component: () =>
      import(/* webpackChunkName: "Details" */ '../views/Details.vue')
  },
  {
    path: '/notAllowed',
    name: 'NotAllowed',
    component: () =>
      import(/* webpackChunkName: "Not-Allowed" */ '../views/Not-Allowed.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/new-password',
    name: 'NewPassword',
    component: NewPassword
  },
  {
    path: '/legal',
    name: 'Legal',
    component: Legal
  },
  {
    path: '/collection/:collectionId',
    name: 'Collection',
    component: Collection,
    meta: { requiresAuth: true, requiresAnon: false }
  },
  {
    path: '/collections',
    name: 'Collections',
    component: Collections,
    meta: { requiresAuth: true, requiresAnon: false }
  },
  {
    path: '/poem/:poemId',
    name: 'Poem',
    component: VideoDetail,
    meta: { requiresAuth: true, requiresAnon: false, requiresPass: true }
  },
  {
    path: '/thanks',
    name: 'Thanks',
    component: Thanks,
    meta: { requiresAuth: false, requiresAnon: false }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})
router.beforeEach(async (to, from, next) => {
  const isAuth = doesJwtExist() && (await isJwtValid())
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (isAuth) {
      if (to.matched.some(record => record.meta.requiresPass)) {
        try {
          const pass = await api.get.access()
          if (pass.status == 200) {
            next()
          } else {
            next({
              path: '/notAllowed'
            })
          }
        } catch (error) {
          next({
            path: '/notAllowed'
          })
        }
      } else {
        next()
      }
    } else {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    }
  } else if (to.matched.some(record => record.meta.requiresAnon)) {
    if (isAuth) {
      next({
        path: '/'
      })
    } else {
      next()
    }
  } else {
    next()
  }
})
export default router
