import api from '../services/api.service'
import { store } from '@/store/index'

export const isJwtValid = async () => {
  try {
    const tokenStatus = await api.get.status()
    return tokenStatus.data.status === '1'
  } catch (error) {
    return false
  }
}

export const doesJwtExist = () => {
  try {
    return store.getters.getToken
  } catch (error) {
    return error
  }
}
