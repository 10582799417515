<template>
  <div class="GalleryImage">
    <template v-if="type != 'collection'">
      <RouterLink :to="`/poem/${id}`">
        <div class="img-container">
          <img :src="imgUrl" :alt="imgAlt" />
        </div>
        <h3 class="GalleryImage-title">{{ title }}</h3>
      </RouterLink>
      <p class="GalleryImage-description" @click="goTo()" v-if="actor != 65">
        <small>{{ description }}</small>
      </p>
    </template>
    <template v-else>
      <RouterLink :to="`/collection/${id}`">
        <div class="img-container">
          <img :src="imgUrl" :alt="imgAlt" />
        </div>
        <h3 class="GalleryImage-title">{{ title }}</h3>
      </RouterLink>
    </template>
  </div>
</template>

<script>
export default {
  name: 'GalleryImage',
  props: {
    imgUrl: String,
    imgAlt: String,
    title: String,
    description: String,
    type: String,
    id: Number,
    poet: Number,
    actor: Number
  },
  methods: {
    goTo() {
      if (this.type === 'poet') {
        this.$router.replace(`/detail/actor/${this.actor}`)
        this.$router.go()
      } else if (this.type === 'actor') {
        this.$router.replace(`/detail/poet/${this.poet}`)
        this.$router.go()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.GalleryImage {
  padding: 10px;
  width: 410px;
  height: 320px;
  img {
    height: 240px;
    width: 100%;
    object-fit: cover;
    border: 1px solid #eee;
  }
  &-title,
  &-description {
    text-align: center;
    margin: 0;
    cursor: pointer;
  }
}
</style>
