import Vue from 'vue'
import VueCryptojs from 'vue-cryptojs'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import { store } from './store/index'
import EasySlider from 'vue-easy-slider'
import i18n from './i18n'
import placeholder from './extensions/placeholder'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Dropdown, Button, Icon, Switch } from 'buefy'
import SweetModal from 'sweet-modal-vue/src/plugin.js'
import Vuelidate from 'vuelidate'
import 'buefy/dist/buefy.css'
import vueVimeoPlayer from 'vue-vimeo-player'
import AsyncComputed from 'vue-async-computed'

Vue.use(Dropdown)
Vue.use(Button)
Vue.use(VueCryptojs)
Vue.use(Icon)
Vue.use(Switch)
Vue.use(EasySlider)
Vue.use(SweetModal)
Vue.use(Vuelidate)
Vue.use(vueVimeoPlayer)
Vue.use(AsyncComputed)

Vue.component('FontAwesomeIcon', FontAwesomeIcon)

library.add(faUserSecret)

Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$placeholder = placeholder

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
