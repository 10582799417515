<template>
  <div class="Details">
    <h1 class="Details-title">{{ $t('navbar.collections') }}</h1>
    <div class="Details-gallery">
      <div class="Gallery">
        <GalleryImage
          v-for="(col, key) in collections"
          :key="key"
          :imgUrl="imgUrlXL + col.image"
          :imgAlt="col[`title_${$i18n.locale}`]"
          :title="col[`title_${$i18n.locale}`]"
          :id="col.id"
          type="collection"
        />
      </div>
    </div>
  </div>
</template>

<script>
import GalleryImage from '@/components/details/GalleryImage'
import api from '@/services/api.service.js'
export default {
  name: 'Collections',
  components: { GalleryImage },
  data() {
    return {
      collections: [],
      imgUrl: process.env.VUE_APP_IMG_URL,
      imgUrlS: process.env.VUE_APP_SMALL_IMG_URL,
      imgUrlM: process.env.VUE_APP_MEDIUM_IMG_URL,
      imgUrlL: process.env.VUE_APP_LARGE_IMG_URL,
      imgUrlXL: process.env.VUE_APP_EXTRA_LARGE_IMG_URL
    }
  },
  async created() {
    this.getCollections()
  },
  methods: {
    async getCollections() {
      const res = await api.get.collections()
      res.data.collections.forEach(col => {
        if (col.status == 1) {
          this.collections.push(col)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.Details {
  &-title {
    text-transform: uppercase;
  }
  &-description {
    margin-right: 70px;
    display: grid;
    grid-template-columns: 300px auto;
    .description-title {
      margin: 0;
    }
    &-img {
      margin-right: 20px;
      img {
        height: 300px;
        width: 300px;
        object-fit: cover;
      }
    }
  }
  &-gallery {
    &-title {
      margin-bottom: 20px;
    }
    margin: 30px auto;
    .Gallery {
      display: flex;
      gap: 15px;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  @media only screen and (max-width: 960px) {
    .Gallery {
      grid-template-columns: repeat(2, 1fr);
      justify-content: center;
    }
  }
  @media only screen and (max-width: 540px) {
    .Details-description {
      display: flex;
      flex-direction: column;
      &-img {
        margin: 0 auto;
      }
    }
    .Gallery {
      grid-template-columns: 1fr;
      justify-content: center;
    }
  }
}
</style>
