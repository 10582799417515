/* eslint-disable */
<template>
  <div class="searchBar">
    <div class="close" @click="close()">x</div>
    <input
      type="text"
      :placeholder="$t('search.placeholder')"
      v-model="term"
      @input="searchTerm"
    />
    <div class="result-container">
      <div v-if="searchHasResults" class="searchResult">
        <div class="resultBlock" v-if="searchPoets.length > 0">
          <h2>{{ $t('search.poets') }}</h2>
          <div class="gallery-container">
            <VueHorizontal>
              <section
                class="poems-gallery"
                v-for="poet in searchPoets"
                :key="poet.id"
                @click="goTo('poet', poet.id)"
              >
                <div :to="`detail/poet/${poet.id}`">
                  <img
                    class="img"
                    :src="imgUrl + poet.image"
                    :alt="poet.title"
                  />
                  <p
                    class="title"
                    v-html="poet.title"
                    v-if="poet.title.length < 20"
                  />
                  <p
                    class="title"
                    v-html="poet.title.substring(0, 19) + '...'"
                    v-if="poet.title.length >= 20"
                  />
                </div>
              </section>
            </VueHorizontal>
          </div>
        </div>
        <div class="resultBlock" v-if="searchActors.length > 0">
          <h2>{{ $t('search.actors') }}</h2>
          <div class="gallery-container">
            <VueHorizontal>
              <section
                class="poems-gallery"
                v-for="actor in searchActors"
                :key="actor.id"
                @click="goTo('actor', actor.id)"
              >
                <div>
                  <img
                    class="img"
                    :src="imgUrl + actor.image"
                    :alt="actor.title"
                  />
                  <p
                    class="title"
                    v-html="actor.title"
                    v-if="actor.title.length < 20"
                  />
                  <p
                    class="title"
                    v-html="actor.title.substring(0, 19) + '...'"
                    v-if="actor.title.length >= 20"
                  />
                </div>
              </section>
            </VueHorizontal>
          </div>
        </div>
        <div class="resultBlock" v-if="searchCollections.length > 0">
          <h2>{{ $t('search.collections') }}</h2>
          <div class="gallery-container">
            <VueHorizontal>
              <section
                class="poems-gallery"
                v-for="collection in searchCollections"
                :key="collection.id"
                @click="goTo('collection', collection.id)"
              >
                <div :to="`/collection/${collection.id}`">
                  <img
                    class="img"
                    :src="imgUrl + collection.image"
                    :alt="collection.title"
                  />
                  <p
                    class="title"
                    v-html="collection.title"
                    v-if="collection.title.length < 20"
                  />
                  <p
                    class="title"
                    v-html="collection.title.substring(0, 19) + '...'"
                    v-if="collection.title.length >= 20"
                  />
                </div>
              </section>
            </VueHorizontal>
          </div>
        </div>
        <div
          class="resultBlock"
          v-if="searchPoems.length > 0 || poetsPoems.length > 0"
        >
          <h2>{{ $t('search.poems') }}</h2>
          <div class="gallery-container">
            <VueHorizontal>
              <section
                class="poems-gallery"
                v-for="poem in searchPoems"
                :key="poem.id"
                @click="goTo('poem', poem.id)"
              >
                <div :to="`/poem/${poem.id}`">
                  <img
                    class="img"
                    :src="imgUrl + poem.image"
                    :alt="poem.title"
                  />
                  <p
                    class="title"
                    v-html="poem.title"
                    v-if="poem.title && poem.title.length < 20"
                  />
                  <p
                    class="title"
                    v-html="poem.title.substring(0, 19) + '...'"
                    v-if="poem.title && poem.title.length >= 20"
                  />
                  <p v-html="poem.poet" />
                </div>
              </section>
              <section
                class="poems-gallery"
                v-for="poem in poetsPoems"
                :key="poem.id"
                @click="goTo('poem', poem.id)"
              >
                <div :to="`/poem/${poem.id}`">
                  <img
                    class="img"
                    :src="imgUrl + poem.image"
                    :alt="poem.title"
                  />
                  <p
                    class="title"
                    v-html="poem.title"
                    v-if="poem.title && poem.title.length < 20"
                  />
                  <p
                    class="title"
                    v-html="poem.title.substring(0, 19) + '...'"
                    v-if="poem.title && poem.title.length >= 20"
                  />
                  <p v-html="poem.poet" />
                </div>
              </section>
            </VueHorizontal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MiniSearch from 'minisearch'
import api from '@/services/api.service'
import VueHorizontal from 'vue-horizontal'
export default {
  name: 'SearchBar',
  components: { VueHorizontal },
  data() {
    return {
      term: '',
      searchEngine: null,
      searchResult: [],
      poetsPoems: [],
      imgUrl: process.env.VUE_APP_IMG_URL
    }
  },
  computed: {
    searchHasResults: function() {
      return this.searchResult.length > 0
    },
    searchActors: function() {
      const actors = this.searchResult.filter(item => item.category === 'actor')
      for (var i = 0; i < actors.length; i++) {
        if (actors[i].id === 65) {
          actors.splice(i, 1)
        }
      }
      return actors
    },
    searchCollections: function() {
      const collections = this.searchResult.filter(
        item => item.category === 'collection'
      )
      return collections
    },
    searchPoets: function() {
      const poets = this.searchResult.filter(item => item.category === 'poet')
      return poets
    },
    searchPoems() {
      const poems = this.searchResult.filter(item => item.category === 'poem')
      return poems
    }
  },
  async created() {
    const actors = await api.get.actors()
    const collections = await api.get.collections()
    const poems = await api.get.poemsSearch()
    const poets = await api.get.poets()
    let searchData = []
    searchData.push(
      actors.data.actors.map(actor => {
        return {
          id: actor.id,
          title: actor.name,
          category: 'actor',
          image: actor.image
        }
      })
    )

    searchData.push(
      collections.data.collections.map(collection => {
        return {
          id: collection.id,
          title: collection[`title_${this.$i18n.locale}`],
          category: 'collection',
          image: collection.image
        }
      })
    )

    searchData.push(
      poems.data.poems.map(poem => {
        return {
          id: poem.id,
          title: poem.title,
          category: 'poem',
          image: poem.image,
          poet: poem.poet
        }
      })
    )

    searchData.push(
      poets.data.poets.map(poet => {
        return {
          id: poet.id,
          title: poet.name,
          category: 'poet',
          poems: poet.poems,
          image: poet.image
        }
      })
    )

    searchData = searchData.flat(1)

    this.searchEngine = new MiniSearch({
      fields: ['title', 'poet'],
      storeFields: ['id', 'title', 'category', 'image', 'poet'],
      prefix: false,
      fuzzy: 0.3
    })
    this.searchEngine.addAll(searchData)
  },
  methods: {
    goTo(type, id) {
      this.$emit('toLink')
      if (type != 'actor' && type != 'poet') {
        this.$router.push(`/${type}/${id}`)
      } else {
        this.$router.push(`/detail/${type}/${id}`)
      }
    },
    searchTerm() {
      if (this.term !== '') {
        this.searchResult = this.searchEngine.search(this.term, {
          combineWith: 'AND',
          fuzzy: 1,
          prefix: true
        })
      } else {
        this.searchResult = []
      }
    },
    plainText(str) {
      str = str.toLowerCase()
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    },
    close() {
      this.$emit('closeModal')
    }
  }
}
</script>

<style lang="scss" scoped>
.close {
  position: fixed;
  top: 20px;
  right: 30px;
  color: white;
  font-size: 30px;
  cursor: pointer;
}
.searchBar {
  @include flex();
  height: 150px;
  width: 90%;
  position: absolute;
  top: 10px;
  input {
    height: 50px;
    width: 600px;
    background-color: $primary;
    padding-left: 42px;
    border: none;
    border-bottom: 1px solid $textColor;
    background-image: url('../assets/search-3-24.png');
    background-position: 10px 10px;
    background-repeat: no-repeat;
    caret-color: white;
    font-size: 20px;
    color: $textColor;
  }
  .result-container {
    position: absolute;
    top: 150px;
    width: 100%;
    height: calc(100vh - 180px);
    overflow-y: scroll;
  }
  .searchResult {
    width: 90%;
    .gallery-container {
      margin: 35px;
      margin-bottom: 100px;
      section {
        background-color: black;
        text-align: center;
        border: 1px solid white;
        margin: 0px 10px;
        width: 200px;
        height: 170px;
        padding: 3px;
        img {
          width: 200px;
          height: 110px;
          object-fit: contain;
          object-position: center;
        }
        .title {
          font-size: 15px;
          color: white;
        }
        p {
          font-size: 13px;
          margin: 0px;
        }
      }
      .category-title {
        font-size: 20px;
        font-weight: bold;
      }
    }
    .resultBlock {
      width: 100%;
    }
    .elem {
      margin: 8px 0px;
      div {
        cursor: pointer;
        color: $textColor;
        &:hover {
          color: white;
        }
      }
    }
  }
  //Scrollbar
  ::-webkit-scrollbar {
    width: 1.5px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #888;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ddd;
  }
}
@media screen and (max-width: 650px) {
  .searchBar {
    input {
      max-width: 300px;
      font-size: 15px;
    }
    .searchResult {
      max-width: 300px;
    }
  }
}
</style>
