<template>
  <div class="collection">
    <section class="info-section">
      <h1>{{ collection['title_' + $i18n.locale] }}</h1>
      <div class="info-container">
        <div class="collection-img">
          <img
            v-if="collection.image"
            :src="imgUrlL + collection.image"
            :alt="collection['title_' + $i18n.locale]"
          />
          <img v-else src="../assets/default.svg" alt="VERSO" />
        </div>
        <div class="description">
          <p>
            {{ collection['description_' + $i18n.locale] }}
          </p>
        </div>
      </div>
    </section>
    <section>
      <div
        class="gallery-container"
        v-if="collection.id != 10 && collection.id != 13"
      >
        <h3 class="category-title">{{ $t('collection.actors') }}</h3>
        <VueHorizontal>
          <section
            class="poems-gallery"
            v-for="actor in actors"
            :key="actor.id"
          >
            <RouterLink :to="`/detail/actor/${actor.id}`">
              <img class="img" :src="imgUrlS + actor.image" :alt="actor.name" />
              <h3>{{ actor.name }}</h3>
            </RouterLink>
          </section>
        </VueHorizontal>
      </div>
      <div class="gallery-container">
        <h3 class="category-title">{{ $t('collection.poets') }}</h3>
        <VueHorizontal>
          <section class="poems-gallery" v-for="poet in poets" :key="poet.poet">
            <RouterLink :to="`/detail/poet/${poet.poet}`">
              <img class="img" :src="imgUrlS + poet.image" :alt="poet.name" />
              <h3>{{ poet.name }}</h3>
            </RouterLink>
          </section>
        </VueHorizontal>
      </div>
      <div class="gallery-container">
        <h3 class="category-title">{{ $t('collection.poems') }}</h3>
        <VueHorizontal>
          <section class="poems-gallery" v-for="poem in poems" :key="poem.id">
            <RouterLink
              :to="`/poem/${poem.id}?collection=${collection.id}`"
              :title="poem.title"
            >
              <img class="img" :src="imgUrlS + poem.image" :alt="poem.title" />
              <h3
                v-html="poem.title"
                v-if="poem.title && poem.title.length < 30"
              />
              <h3
                class="title"
                v-html="poem.title.substring(0, 27) + '...'"
                v-if="poem.title && poem.title.length >= 30"
              />
              <p v-html="poem.poet" />
            </RouterLink>
          </section>
        </VueHorizontal>
      </div>
    </section>
  </div>
</template>

<script>
import VueHorizontal from 'vue-horizontal'
import { mapState } from 'vuex'
import api from '@/services/api.service.js'
export default {
  name: 'Collection',
  data() {
    return {
      collectionId: '',
      collection: '',
      actors: [],
      poets: [],
      poems: [],
      imgUrl: process.env.VUE_APP_IMG_URL,
      imgUrlS: process.env.VUE_APP_SMALL_IMG_URL,
      imgUrlM: process.env.VUE_APP_MEDIUM_IMG_URL,
      imgUrlL: process.env.VUE_APP_LARGE_IMG_URL
    }
  },
  components: {
    VueHorizontal
  },
  computed: {
    ...mapState(['token'])
  },
  mounted() {
    this.getCollection()
  },
  methods: {
    async getCollection() {
      this.collectionId = this.$route.params.collectionId
      const res = await api.get.collection(this.collectionId)
      this.collection = res.data.collection
      const poemsres = await api.get.collectionPoems(this.collectionId)
      this.poems = poemsres.data.poems
      const actorsres = await api.get.collectionActors(this.collectionId)
      this.actors = actorsres.data.actors
      this.actors.forEach((actor, index) => {
        if (actor.id == 65) {
          this.actors.splice(index, 1)
        }
      })
      const poetsres = await api.get.collectionPoets(this.collectionId)
      this.poets = poetsres.data.poets
    }
  }
}
</script>

<style lang="scss" scoped>
.collection {
  flex-direction: column;
  .main-slider {
    margin: 0 auto;
  }
  .home-container {
    margin-bottom: 80px;
    .home-title {
      margin: 40px 0px;
    }
  }
  .slider {
    height: 550px !important; //overwrite library
    .slider-items {
      .slider-item {
        padding: 50px;
        @include flex();
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        .slider-img {
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
        }
        div {
          z-index: 2;
          padding: 30px;
          width: 80%;
          height: 300px;
          h1,
          p {
            text-shadow: -3px 0px 5px #191818;
          }
          p {
            margin: 30px 0px;
            font-size: 20px;
            color: white;
          }
          .slider-btn {
            font-weight: bolder;
            &:hover {
              color: black;
            }
          }
        }
      }
    }
  }
  &-container {
    .login {
      @include flex(space-between);
      height: 200px;
      &-descriptions {
        width: 45%;
        line-height: 30px;
      }
      &-section {
        @include flex();
        flex-direction: column;
        width: 45%;
      }
    }
  }
  &-title {
    text-align: center;
  }
  .gallery-container {
    margin: 35px 0px;
    margin-bottom: 100px;
    section {
      background-color: black;
      text-align: center;
      //border: 1px solid white;
      margin: 0px 10px;
    }
    .category-title {
      font-size: 20px;
      font-weight: bold;
    }
  }
  .info-section {
    h1 {
      font-size: 24px;
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .info-container {
      @include flex($align: flex-start, $direction: column);
      .collection-img {
        width: 100%;
        height: 350px;
        overflow: hidden;
        @include flex();
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .description {
        margin-top: 10px;
      }
    }
  }
  .poems-gallery {
    padding: 16px 15px;
    background: #f5f5f5;
    cursor: pointer;
    .img {
      width: 350px;
      height: 200px;
      margin: auto;
      object-fit: cover;
      border: 1px solid white;
    }
    h3 {
      font-size: 20px;
      margin-bottom: 10px;
    }
    p {
      margin: 0px;
    }
  }
}

@media screen and (max-width: 1100px) {
  .collection {
    .slider {
      width: 100vw !important; //overwrite library
      height: 60vh !important; //overwrite library
    }
  }
}

@media screen and (max-width: 769px) {
  .collection {
    .slider {
      width: 100vw !important;
      height: 50vh !important;

      .slider-items {
        .slider-item {
          padding: 20px;
          div {
            height: 220px;
            h1 {
              font-size: 30px !important;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .collection {
    .slider {
      height: 30vh !important; //overwrite library
      .slider-items {
        .slider-item {
          div {
            height: 180px;
            h1 {
              font-size: 28px !important;
              margin: 0px !important;
            }
            p {
              margin: 10px 0px 20px 0px !important;
            }
          }
        }
      }
    }
    .gallery-container {
      margin: 25px;
      margin-bottom: 80px;
      .category-title {
        font-size: 18px;
      }
    }

    .poems-gallery {
      padding: 12px 10px;
      .img {
        width: 200px;
        height: 120px;
      }
      h3 {
        font-size: 16px;
        margin-bottom: 10px;
      }
      p {
        font-size: 13px;
        margin: 0px;
      }
    }
  }
}

@media screen and (max-width: 580px) {
  .home {
    &-container {
      padding: 0px;
      padding-left: 20px;
      .home-title {
        font-size: 20px;
        padding: 10px;
        margin: 0px;
      }
      .login {
        height: auto;
        .login-descriptions {
          padding-left: 10px;
          li {
            font-size: 12px;
          }
        }
        .login-section {
          a,
          p {
            font-size: 10px;
          }
        }
        .primary-btn {
          padding: 10px;
        }
      }
    }
  }
}
@media screen and (max-width: 476px) {
  .home {
    .slider {
      width: 100vw !important; //overwrite library
      height: 25vh !important; //overwrite library
      .slider-items {
        .slider-item {
          div {
            height: 134px;
            h1 {
              font-size: 19px !important;
            }

            p {
              margin: 30px 0px;
              font-size: 15px;
              color: white;
            }
            .slider-btn {
              font-weight: bolder;
              font-size: 12px;
              padding: 5px 10px;
              &:hover {
                color: black;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 415px) {
  .home {
    .slider {
      .slider-items {
        .slider-item {
          div {
            height: 160px;
            h1 {
              font-size: 18px !important;
            }

            p {
              margin: 30px 0px;
              font-size: 12px;
              color: white;
            }
            .slider-btn {
              font-weight: bolder;
              font-size: 10px;
              padding: 5px 10px;
              &:hover {
                color: black;
              }
            }
          }
        }
      }
    }
  }
}
</style>
