<template>
  <div class="vimeoPlayer video" />
</template>

<script>
import Player from '@vimeo/player'

export default {
  name: 'VideoPlayer',
  props: {
    videoId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      player: null
    }
  },
  methods: {
    install() {
      const _this = this
      this.player = new Player(this.$el, {
        id: this.videoId,
        width: 970,
        height: 510,
        frameborder: 0,
        autoplay: true,
        responsive: true
      })
      this.player.on('ended', function() {
        _this.$emit('ended')
      })
      this.player.on('pause', function() {
        _this.$emit('pause')
      })
      this.player.on('play', function() {
        _this.$emit('play')
      })
    }
  },
  mounted() {
    this.install()
  }
}
</script>

<style lang="scss" scoped>
.video {
  margin-right: 20px;
}
.miniature {
  iframe {
    width: 100%;
  }
}
</style>
