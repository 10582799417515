<template>
  <div class="ips">
    <h2>{{ $t('profile.authorized-ips') }}</h2>
    <div class="search-bar">
      <input type="text" :placeholder="$t('common.search')" />
    </div>
    <ul class="students-list">
      <li v-for="(ip, index) in ips" :key="index" :class="isEven(index)">
        <div>{{ ip }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Ips',
  data() {
    return {
      ips: [
        'domaddfdfffffin',
        'domfffffainw',
        'dkffffffffffalsdf',
        'dasdfagggggg'
      ]
    }
  },
  methods: {
    isEven(index) {
      if (index % 2 === 0) {
        return 'grey'
      } else {
        return ''
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.ips {
  .search-bar {
    @include flex(space-between);
    input {
      background: #000;
      border: 0.5px solid #fff;
      color: #fff;
      padding: 15px 6px;
      height: 45px;
      width: 100%;
      background-size: 20px;
      padding-left: 24px;
      background-position: 4px 13px;
      background-repeat: no-repeat;
      background-image: url(../../../assets/icons/search.png);
    }
  }
  ul {
    padding: 0px;
    list-style-type: none;
    border: 1px solid #fff;
    li {
      @include flex;
      padding: 6px 12px;
      &.grey {
        background: $darkGrey;
      }
      &:hover {
        cursor: pointer;
        background: #fff;
        transition: 0.2 all ease-in-out;
      }
      .secondary-btn {
        border: 0px;
      }
    }
  }
}
</style>
