<template>
  <div id="app">
    <NavBar />
    <RouterView class="Container" />
    <Footer />
  </div>
</template>

<script>
import Footer from './components/Footer.vue'
import NavBar from './components/NavBar.vue'
//import { store } from './store/index'

export default {
  components: { NavBar, Footer }
}
</script>

<style lang="scss">
#app {
  font-family: $main-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 0 auto;
}
</style>
