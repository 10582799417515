<template>
  <div class="plans">
    <h3 v-html="$t('plans.title')" />
    <div class="list">
      <a
        :href="plan.link"
        target="_blank"
        class="plan"
        v-for="plan in plans"
        :key="plan.key"
      >
        <h5>{{ $t(`plans.${plan.time}`) }}</h5>
        <h5>{{ plan.price }}</h5>
      </a>
    </div>
    <RouterLink to="/login" class="post">
      <h3 v-html="$t('plans.register')" />
    </RouterLink>
  </div>
</template>

<script>
export default {
  name: 'Plans',
  data() {
    return {
      plans: [
        {
          key: 0,
          time: 'one',
          price: '2,99 €',
          link: 'https://www.poetica2puntocero.com/producto/pase-1-mes-verso/'
        },
        {
          key: 1,
          time: 'six',
          price: '12 €',
          link: 'https://www.poetica2puntocero.com/producto/6-meses/'
        },
        {
          key: 2,
          time: 'twelve',
          price: '24€',
          link: 'https://www.poetica2puntocero.com/producto/verso-12-meses/'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.plans {
  @include flex($direction: column);
  h3 {
    margin: 20px 0px;
    font-size: 25px;
    text-align: center;
    font-weight: 600;
  }
  .post {
    &:hover {
      text-decoration: underline;
    }
  }
  .list {
    @include flex;
    gap: 30px;
    flex-wrap: wrap;
    .plan {
      width: 210px;
      height: 150px;
      border: 1px solid white;
      @include flex($direction: column);
      gap: 20px;
      text-transform: uppercase;
      font-size: 40px;
      box-shadow: 2px 2px $textColor;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        background: $textColor;
      }
    }
  }
}
</style>
