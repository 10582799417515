export default {
  loremShort:
    'numquam incidunt doloribus omnis ullam laborum rem provident, earum inventore',
  lorem: `numquam incidunt doloribus omnis ullam laborum rem provident, earum inventore
numquam incidunt doloribus omnis ullam laborum rem provident, earum inventore
numquam incidunt doloribus omnis ullam laborum rem provident, earum inventore
numquam incidunt doloribus omnis ullam laborum rem provident, earum inventore
numquam incidunt doloribus omnis ullam laborum rem provident, earum inventore`,
  rectImg:
    'https://www.poetica2puntocero.com/web/wp-content/uploads/2021/03/Destacada_Web_Abrazo_Peter_Wever.jpg',
  squareImg:
    'https://www.poetica2puntocero.com/web/wp-content/uploads/2021/02/WebPortadaFabulas.jpg',
  textShort: 'Ullam laborum rem'
}
