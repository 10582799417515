import axios from 'axios'
import { store } from '../store/index'
const AXIOS = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})
const AXIOS_SUGGESTIONS = axios.create({
  baseURL: process.env.VUE_APP_SUGGESTIONS_URL
})

const AXIOS_MULTIMEDIA = axios.create({
  baseURL: process.env.VUE_APP_MULTIMEDIA_URL
})
const AXIOS_PAYMENTS = axios.create({
  baseURL: process.env.VUE_APP_PAYMENTS
})
AXIOS.interceptors.request.use(
  async config => {
    config.headers = {
      'Authorization': `Bearer ${store.state.token}`
    }
    return config
  },
  error => {
    Promise.reject(error).then(r => console.error(r))
  },
  error => {
    // Return any error which is not due to authentication back to the calling service
    if (error.response.status !== 401) {
      return new Promise((resolve, reject) => {
        reject(error)
      })
    }
    return AXIOS.post('/refresh', store.getters.getRefreshToken)
      .then(token => {
        const config = error.config
        config.headers['Authorization'] = `Bearer ${token}`
        store.commit('setToken', token)
        return new Promise((resolve, reject) => {
          axios
            .request(config)
            .then(response => {
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      })
      .catch(error => {
        Promise.reject(error)
      })
  }
)
AXIOS_PAYMENTS.interceptors.request.use(
  async config => {
    config.headers = {
      'Authorization': `Bearer ${store.state.token}`
    }
    return config
  },
  error => {
    Promise.reject(error).then(r => console.error(r))
  },
  error => {
    // Return any error which is not due to authentication back to the calling service
    if (error.response.status !== 401) {
      return new Promise((resolve, reject) => {
        reject(error)
      })
    }
    return AXIOS.post('/refresh', store.getters.getRefreshToken)
      .then(token => {
        const config = error.config
        config.headers['Authorization'] = `Bearer ${token}`
        store.commit('setToken', token)
        return new Promise((resolve, reject) => {
          axios
            .request(config)
            .then(response => {
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      })
      .catch(error => {
        Promise.reject(error)
      })
  }
)
export default Object.freeze({
  get: {
    actors: () => AXIOS.get('/actors'),
    actor: id => AXIOS.get(`/actor/${id}`),
    poets: () => AXIOS.get('/poets'),
    poet: id => AXIOS.get(`/poet/${id}`),
    collections: () => AXIOS.get('/collections'),
    collection: id => AXIOS.get(`/collection/${id}`),
    poems: () => AXIOS.get('/poems'),
    plans: () => AXIOS.get('/plans/public'),
    poem: id => AXIOS.get(`/poem/${id}`),
    mainPoem: id => AXIOS.get(`/poem/${id}?visit=true`),
    user: () => AXIOS.get('user'),
    userInstitution: () => AXIOS.get('user/institution'),
    suggestions: id => AXIOS_SUGGESTIONS.get(`/poems/${id}`),
    //LLAMADAS DE LA HOME
    lastPoems: () => AXIOS.get('/poems/latest'),
    popularPoems: () => AXIOS.get('/poems/popular'),
    popularPoets: () => AXIOS.get('/poets/popular'),
    popularCollections: () => AXIOS.get('/collections/popular'),
    poemsSearch: () => AXIOS.get('/poems/search'),
    access: () => AXIOS.get('/user/access'),
    status: () => AXIOS.get('/user/status'),
    institutionCode: () => AXIOS.get('/institutionCode'),
    //institucion
    codes: () => AXIOS.get('/institution/codes'),
    institutionUsers: () => AXIOS.get('/institution/codes/users'),
    pendingUsers: () => AXIOS.get('/institution/codes/users/pending'),
    acceptedUsers: () => AXIOS.get('/institution/codes/users/accepted'),
    rejectedUsers: () => AXIOS.get('/institution/codes/users/rejected'),
    //collection
    collectionPoems: id => AXIOS.get(`/collection/${id}/poems`),
    collectionActors: id => AXIOS.get(`/collection/${id}/actors`),
    collectionPoets: id => AXIOS.get(`/collection/${id}/poets`)
  },
  post: {
    newOrder: product => AXIOS_PAYMENTS.post('/', product),
    newPoem: poem => AXIOS.post('/poems', poem),
    newActorName: name => AXIOS.post('/actors', name),
    newPoetName: name => AXIOS.post('/poets', name),
    newCollectionTitle: title => AXIOS.post('/collections', title),
    actor: actor => AXIOS.post('/actors', actor),
    poet: poet => AXIOS.post('/poets', poet),
    collection: collection => AXIOS.post('/collections', collection),
    video: file => AXIOS_MULTIMEDIA.post('/videos', file),
    image: file => AXIOS_MULTIMEDIA.post('/images', file),
    poemCollection: collection =>
      AXIOS.post(`/collection/${collection.id}/poems`, collection.poem),
    login: user => AXIOS.post('/user/login', user),
    register: user => AXIOS.post('/user/register', user),
    activate: code => AXIOS.post('/user/activate', code),
    recovery: email => AXIOS.post('/user/recovery', email),
    newpassword: user => AXIOS.post('/user/password', user),
    code: code => AXIOS.post('/institution/codes', code),
    acceptUser: user => AXIOS.post('/institution/codes/users/accept', user),
    rejectUser: user => AXIOS.post('/institution/codes/users/reject', user)
  },
  put: {
    actor: actor => AXIOS.put(`/actor/${actor.id}`, actor),
    poet: poet => AXIOS.put(`/poet/${poet.id}`, poet),
    collection: collection =>
      AXIOS.put(`/collection/${collection.id}`, collection),
    poem: poem => AXIOS.put(`/poem/${poem.id}`, poem),
    user: user => AXIOS.put('/user', user)
  }
})
