<template>
  <div class="content activate">
    <div class="auth-activate">
      <template v-if="!code && status !== '1'">
        <h1 v-html="$t('common.congrats')" />
        <p v-html="$t('auth.activate-intro')" />
        <hr />
        <h3 v-html="$t('auth.insert-code-tit')" />
        <p v-html="$t('auth.insert-code-p')" />
        <div class="code">
          <input
            type="text"
            v-for="n in 6"
            :name="`code${n}`"
            :key="n"
            maxlength="1"
            v-model="insertCode[n - 1]"
            v-on:keyup="nextFocus(n, $event)"
          />
        </div>
        <div
          class="error"
          v-if="status && status !== '1'"
          v-html="$t('auth.code-error')"
        />
        <div class="buttons">
          <button class="btn black-btn" v-html="$t('common.cancel')" />
          <button
            class="primary-btn"
            v-html="$t('common.next')"
            @click="sendCode()"
          />
        </div>
      </template>
      <template v-if="status && status === '1'">
        <h1 v-html="$t('common.great')" />
        <p v-html="$t('auth.success')" />
        <hr />
        <RouterLink class="primary-btn" to="login" v-html="$t('auth.login')" />
      </template>
    </div>
  </div>
</template>

<script>
import api from '@/services/api.service.js'
export default {
  name: 'Activate',
  props: {
    code: {
      type: String,
      default: '',
      required: false
    }
  },
  data() {
    return {
      insertCode: [],
      status: false
    }
  },
  async created() {
    if (this.code) {
      try {
        const res = await api.activate({ token: this.code })
        this.status = res.data.status
        //await this.$router.push('/')
      } catch (error) {
        return error
      }
    } else {
      //console.log('no code')
    }
  },
  methods: {
    async sendCode() {
      try {
        let token = { token: this.insertCode.join('') }
        const res = await api.post.activate(token)
        this.status = res.data.status
        //await this.$router.push('/')
      } catch (error) {
        return error
      }
    },
    nextFocus(n, event) {
      if (n < 6 && event.target.value !== '') {
        document.querySelector(`input[name="code${n + 1}"]`).focus()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.activate {
  @include flex();
  .auth-activate {
    @include flex(false, false, column);
    border: 1px solid #fff;
    padding: 15px;
    min-width: 500px;
    h1 {
      color: #fff;
      font-size: 3em;
    }
    p {
      margin: 5px 0px;
      font-size: 18px;
      max-width: 80%;
      text-align: center;
    }
    h3 {
      margin-bottom: 5px;
      margin-top: 10px;
    }
    .code {
      display: flex;
      padding: 8px;
      border-bottom: 2px solid white;
      margin-bottom: 16px;
      position: relative;
      input {
        border: 0;
        background: white;
        border-radius: 0;
        width: 44px;
        height: 44px;
        margin: 0 4px;
        font-size: 28px;
        text-align: center;
      }
      .error {
        color: red;
        position: absolute;
        right: -20px;
        top: 16px;
        font-size: 2em;
      }
    }
    h3 {
      font-size: 2em;
    }
    hr {
      border-top: 1px solid $darkGrey;
      width: 90%;
      margin: 10px auto 20px;
    }
    .btn {
      margin: 0 8px;
    }
    .error {
      margin-bottom: 16px;
    }
    .logo {
      max-width: 200px;
      margin-top: 36px;
    }
  }
}
</style>
