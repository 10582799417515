<template>
  <div class="home">
    <Slider
      animation="fade"
      :indicators="false"
      class="main-slider"
      interval="4000"
    >
      <SliderItem
        v-for="(i, index) in randomList"
        :key="index"
        :style="i"
        :max-width="'100%'"
      >
        <img :src="i.img" class="slider-img" />
        <div>
          <h1 style="font-size: 3rem; text-align: start;">
            {{ $t(`slider.${i.title}`) }}
          </h1>
          <p />
          <RouterLink :to="i.link" class="secondary-btn slider-btn">
            {{ $t(`slider.${i.btnText}`) }}
          </RouterLink>
        </div>
      </SliderItem>
    </Slider>
    <section class="home-container">
      <h2 class="home-title">
        {{ $t('home.title') }}
      </h2>
      <div class="login">
        <ul class="login-descriptions">
          <li v-html="$t('home.desc1')" />
          <li>
            {{ $t('home.desc2') }}
          </li>
          <li>
            {{ $t('home.desc3') }}
          </li>
          <li>
            {{ $t('home.desc4') }}
          </li>
        </ul>
        <div class="vertical-divider" />
        <div class="login-section">
          <RouterLink to="/login" class="primary-btn">
            {{ $t('home.create-account') }}
          </RouterLink>
          <p>
            <RouterLink class="hyper" to="/login">
              {{ $t('home.login.link') }}
            </RouterLink>
            {{ $t('home.login.if') }}
          </p>
        </div>
      </div>
    </section>
    <section>
      <div class="gallery-container">
        <h3 class="category-title">{{ $t('home.last-poems') }}</h3>
        <VueHorizontal>
          <section
            class="poems-gallery"
            v-for="poem in lastPoems"
            :key="poem.poem"
          >
            <RouterLink :to="`/poem/${poem.id}`">
              <img class="img" :src="imgUrlS + poem.image" :alt="poem.title" />
              <h3>{{ poem.title }}</h3>
            </RouterLink>
            <RouterLink :to="`/detail/poet/${poem.poet}`">
              <p>{{ poem.poet_name }}</p>
            </RouterLink>
          </section>
        </VueHorizontal>
      </div>
      <div class="gallery-container">
        <h3 class="category-title">{{ $t('home.popular-poems') }}</h3>
        <VueHorizontal>
          <section
            class="poems-gallery"
            v-for="poem in popularPoems"
            :key="poem.poem"
          >
            <RouterLink :to="`/poem/${poem.id}`">
              <img class="img" :src="imgUrlS + poem.image" :alt="poem.title" />
              <h3>{{ poem.title }}</h3>
            </RouterLink>
            <RouterLink :to="`/detail/poet/${poem.poet}`">
              <p>{{ poem.poet_name }}</p>
            </RouterLink>
          </section>
        </VueHorizontal>
      </div>
      <div class="gallery-container">
        <h3 class="category-title">{{ $t('home.popular-poets') }}</h3>
        <VueHorizontal>
          <section
            class="poems-gallery"
            v-for="poet in popularPoets"
            :key="poet.id"
          >
            <RouterLink :to="`/detail/poet/${poet.id}`">
              <img class="img" :src="imgUrlS + poet.image" :alt="poet.name" />
              <h3>{{ poet.name }}</h3>
            </RouterLink>
          </section>
        </VueHorizontal>
      </div>
      <div class="gallery-container">
        <h3 class="category-title">{{ $t('home.popular-collections') }}</h3>
        <VueHorizontal>
          <section
            class="poems-gallery"
            v-for="collection in popularCollections"
            :key="collection.id"
          >
            <RouterLink :to="`/collection/${collection.id}`">
              <img
                class="img"
                :src="imgUrlS + collection.image"
                :alt="collection.title_esp"
              />
              <h3>{{ collection['title_' + $i18n.locale] }}</h3>
            </RouterLink>
          </section>
        </VueHorizontal>
      </div>
    </section>
    <Plans v-if="status == 0" />
  </div>
</template>

<script>
// @ is an alias to /src
import VueHorizontal from 'vue-horizontal'
import { mapState } from 'vuex'
import api from '@/services/api.service.js'
import Plans from '@/components/Plans.vue'
export default {
  name: 'Home',
  data() {
    return {
      list: [
        {
          img: require('../assets/slider/lorca.jpg'),
          width: '100%',
          height: '100%',
          title: 'lorca.title',
          subtitle: 'lorca.subtitle',
          btnText: 'lorca.button',
          link: '/collection/19'
        },
        {
          img: require('../assets/slider/echanove.png'),
          width: '100%',
          height: '100%',
          title: 'echanove.title',
          subtitle: 'echanove.subtitle',
          btnText: 'echanove.button',
          link: '/detail/poet/64'
        },
        {
          img: require('../assets/slider/aguirre.jpg'),
          width: '100%',
          height: '100%',
          title: 'aguirre.title',
          subtitle: 'aguirre.subtitle',
          btnText: 'aguirre.button',
          link: '/collection/13'
        },
        {
          img: require('../assets/slider/caba.jpg'),
          width: '100%',
          height: '100%',
          title: 'caba.title',
          subtitle: 'caba.subtitle',
          btnText: 'caba.button',
          link: '/collection/7'
        },
        {
          img: require('../assets/slider/pamplona.jpg'),
          width: '100%',
          height: '100%',
          title: 'pamplona.title',
          subtitle: 'pamplona.subtitle',
          btnText: 'pamplona.button',
          link: '/collections'
        }
      ],
      lastPoems: '',
      popularPoems: '',
      popularPoets: '',
      popularCollections: '',
      imgUrl: process.env.VUE_APP_IMG_URL,
      imgUrlS: process.env.VUE_APP_SMALL_IMG_URL,
      imgUrlM: process.env.VUE_APP_MEDIUM_IMG_URL,
      imgUrlL: process.env.VUE_APP_LARGE_IMG_URL
    }
  },
  created() {
    this.getLastPoems()
    this.getPopularPoems()
    this.getPopularPoets()
    this.getPopularCollections()
  },
  components: {
    VueHorizontal,
    Plans
  },
  computed: {
    ...mapState(['token', 'status']),
    randomList() {
      const list = this.list
      return list.sort(function() {
        return 0.5 - Math.random()
      })
    }
  },
  methods: {
    async getLastPoems() {
      const res = await api.get.lastPoems()
      for await (let poem of res.data.poems) {
        const res2 = await api.get.poet(poem.poet)
        poem.poet_name = res2.data.poet.name
      }
      this.lastPoems = res.data.poems
    },
    async getPopularPoems() {
      const res = await api.get.popularPoems()
      for await (let poem of res.data.poems) {
        const res2 = await api.get.poet(poem.poet)
        poem.poet_name = res2.data.poet.name
      }
      this.popularPoems = res.data.poems
    },
    async getPopularPoets() {
      const res = await api.get.popularPoets()
      this.popularPoets = res.data.poets
    },
    async getPopularCollections() {
      const res = await api.get.popularCollections()
      this.popularCollections = res.data.collections
    }
  }
}
</script>

<style lang="scss" scoped>
.verkami {
  color: white;
  text-align: center;
  font-size: 20px;
  margin: 0px auto 20px;
  display: block;
}
.home {
  flex-direction: column;
  .main-slider {
    margin: 0 auto;
  }
  .home-container {
    margin-bottom: 80px;
    .home-title {
      margin: 40px 0px;
    }
  }
  .slider {
    height: 550px !important; //overwrite library
    .slider-items {
      .slider-item {
        padding: 50px;
        @include flex();
        flex-direction: column;
        align-items: flex-start;
        .slider-img {
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          object-fit: cover;
        }
        div {
          z-index: 2;
          padding: 30px;
          width: 80%;
          height: 300px;
          h1,
          p {
            text-shadow: -3px 0px 5px #191818;
          }
          p {
            margin: 30px 0px;
            font-size: 20px;
            color: white;
          }
          .slider-btn {
            font-weight: bolder;
            margin-top: 15px;
            &:hover {
              color: black;
            }
          }
        }
      }
    }
  }
  img {
    width: 100px;
    margin-right: 10px;
  }
  &-container {
    .login {
      @include flex(space-between);
      height: 200px;
      margin: 35px;
      &-descriptions {
        width: 45%;
        line-height: 30px;
        text-align: center;
      }
      &-section {
        @include flex();
        flex-direction: column;
        width: 45%;
        p {
          margin-top: 5px;
        }
      }
      @media (max-width: 1000px) {
        flex-direction: column;
        text-align: center;
        .vertical-divider {
          display: none;
        }
        .login-descriptions {
          width: 100%;
        }
      }
    }
  }
  &-title {
    text-align: center;
  }
  .gallery-container {
    margin: 0px 35px;
    section {
      background-color: black;
      text-align: center;
      margin: 0px 10px;
      width: 410px;
      height: 340px;
    }
    .category-title {
      font-size: 30px;
      font-weight: 500;
    }
  }

  .poems-gallery {
    padding: 16px 15px;
    background: #f5f5f5;
    cursor: pointer;
    .img {
      width: 350px;
      height: 200px;
      margin: auto;
      object-fit: cover;
      border: 1px solid #eee;
    }
    h3 {
      font-size: 20px;
      margin-bottom: 10px;
    }
    p {
      margin: 0px;
    }
  }
}

@media screen and (max-width: 1100px) {
  .home {
    .slider {
      .slider-items {
        .slider-item {
          padding: 20px;
          div {
            height: 220px;
            width: 100%;
            h1 {
              font-size: 35px !important;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 769px) {
  .home {
    .slider {
      width: calc(100vw - 40px) !important;
      height: 50vh !important;
      .slider-items {
        .slider-item {
          padding: 20px;
          div {
            height: 220px;
            width: 100%;
            h1 {
              font-size: 30px !important;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .home {
    .slider {
      height: 30vh !important; //overwrite library
      .slider-items {
        .slider-item {
          div {
            height: 180px;
            h1 {
              font-size: 25px !important;
              margin: 0px !important;
            }
            p {
              margin: 10px 0px 20px 0px !important;
            }
          }
        }
      }
    }
    .gallery-container {
      margin: 25px;
      margin-bottom: 80px;
      .category-title {
        font-size: 18px;
      }
      section {
        width: 220px;
        height: 220px;
      }
    }
    .poems-gallery {
      padding: 12px 10px;
      .img {
        width: 200px;
        height: 120px;
      }
      h3 {
        font-size: 16px;
        margin-bottom: 10px;
      }
      p {
        font-size: 13px;
        margin: 0px;
      }
    }
  }
  .login-descriptions {
    li {
      font-size: 16px !important;
    }
  }
  .login-section {
    width: 100% !important;
    margin-top: 20px;
    p,
    a {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 580px) {
  .home {
    &-container {
      padding: 0px;
      margin-bottom: 5px !important;
      h2 {
        margin: 10px 0px !important;
      }
      .home-title {
        font-size: 20px;
        padding: 10px;
        margin: 0px;
      }
      .login {
        height: auto;
        margin: 20px 0px;
        .login-descriptions {
          li {
            font-size: 12px;
          }
        }
        .login-section {
          padding: 20px 0px;
          a,
          p {
            font-size: 10px;
          }
        }
        .primary-btn {
          padding: 10px;
        }
      }
    }
    .gallery-container {
      margin: 5px;
      margin-bottom: 20px;
      .category-title {
        font-size: 16px;
      }
    }
  }
}
@media screen and (max-width: 476px) {
  .home {
    .slider {
      width: calc(100vw - 40px) !important; //overwrite library
      //height: 25vh !important; //overwrite library
      margin: auto;
      .slider-items {
        .slider-item {
          div {
            height: auto;
            h1 {
              font-size: 19px !important;
            }

            p {
              margin: 30px 0px;
              font-size: 15px;
              color: white;
            }
            .slider-btn {
              font-weight: bolder;
              font-size: 12px;
              padding: 5px 10px;
              &:hover {
                color: black;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 415px) {
  .home {
    .slider {
      .slider-items {
        .slider-item {
          div {
            height: auto;
            padding-top: 30px !important;
            h1 {
              font-size: 18px !important;
            }

            p {
              margin: 30px 0px;
              color: white;
            }
            .slider-btn {
              font-weight: bolder;
              font-size: 10px;
              padding: 5px 10px;
              &:hover {
                color: black;
              }
            }
          }
        }
      }
    }
  }
}
</style>
