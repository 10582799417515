<template>
  <div class="NavBar">
    <RouterLink to="/">
      <img class="logo" src="../assets/verso.svg" />
    </RouterLink>
    <nav class="NavBar-nav">
      <RouterLink to="/collections">{{ $t('navbar.collections') }}</RouterLink>
      <!-- <a target="_blank" href="https://www.poetica2puntocero.com/tienda/">
        {{ $t('navbar.shop') }}
      </a> -->
      <span
        @click="showModal = !showModal"
        class="search-nav"
        v-if="status === 1"
      >
        {{ $t('navbar.search') }}
      </span>
      <RouterLink to="/profile" v-if="status === 1">
        {{ $t('navbar.profile') }}
      </RouterLink>
      <span class="search-nav" v-if="status === 1" @click="logout">
        {{ $t('navbar.logout') }}
      </span>
      <RouterLink to="/login" v-if="status === 0">
        {{ $t('navbar.free-trial') }}
      </RouterLink>
      <RouterLink to="/login" v-if="status === 0">
        {{ $t('navbar.login') }}
      </RouterLink>
      <Modal v-if="showModal">
        <SearchBar />
      </Modal>
      <SelectLanguage />
    </nav>
    <Slide class="showBurger" right :closeOnNavigation="true">
      <nav class="NavBar-nav-burger">
        <!-- <a target="_blank" href="https://www.poetica2puntocero.com/tienda/">
          {{ $t('navbar.shop') }}
        </a> -->
        <RouterLink to="/collections" v-if="status === 1">
          {{ $t('navbar.collections') }}
        </RouterLink>
        <span
          @click="showModal = !showModal"
          class="search-nav"
          v-if="status === 1"
        >
          {{ $t('navbar.search') }}
        </span>
        <span class="search-nav" v-if="status === 1" @click="logout">
          {{ $t('navbar.logout') }}
        </span>
        <RouterLink to="/login" v-if="status === 0">
          {{ $t('navbar.free-trial') }}
        </RouterLink>
        <RouterLink to="/login" v-if="status === 0">
          {{ $t('navbar.login') }}
        </RouterLink>
        <SelectLanguage />
      </nav>
    </Slide>
    <Modal v-if="showModal">
      <SearchBar @toLink="closeModal()" @closeModal="closeModal()" />
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/layout/Modal.vue'
import SearchBar from '@/components/SearchBar.vue'
import { Slide } from 'vue-burger-menu'
import { mapState, mapMutations } from 'vuex'
import SelectLanguage from './SelectLanguage/SelectLanguage'

export default {
  name: 'NavBar',
  data() {
    return {
      showModal: false
    }
  },
  components: {
    Modal,
    SearchBar,
    Slide,
    SelectLanguage
  },
  computed: {
    ...mapState(['status'])
  },
  methods: {
    ...mapMutations(['setToken', 'setStatus', 'setUser']),
    logout() {
      this.setToken('')
      this.setStatus(0)
      this.setUser('')
      this.$router.push({ name: 'Home' })
    },
    toggleSearch() {
      this.showModal = !this.showModal
    },
    closeModal() {
      this.showModal = false
    }
  },
  beforeRouteLeave() {
    this.showModal = false
  }
}
</script>

<style lang="scss" scoped>
.NavBar {
  @include flex(space-between);
  padding: 40px 30px;
  //margin-bottom: 40px;
  height: $navHeight;
  .logo {
    width: 90px;
  }
  &-logo {
    font-weight: 600;
    color: white;
    font-size: 25px;
    span {
      color: $textColor;
    }
  }
  &-nav,
  &-nav-burger {
    @include flex(flex-start, flex-start);
    a,
    span,
    .nav-item {
      margin-right: 90px;
      cursor: pointer;
      text-transform: uppercase;
      color: white;
      &:hover {
        color: $textColor;
      }
    }
  }
  &-nav-burger {
    flex-direction: column;
    margin: 0;
    a,
    span {
      padding: 15px 0px;
      margin: 0px;
      font-weight: 300;
    }
  }
}
.bm-menu {
  background-color: $primary !important;
}
.bm-burger-bars {
  background-color: $primary !important;
}
.showBurger {
  display: none;
}

@media screen and (max-width: 1120px) {
  .NavBar {
    &-nav {
      a,
      span {
        margin-right: 50px;
      }
    }
  }
}
@media screen and (max-width: 845px) {
  .NavBar-nav {
    display: none;
  }
  .showBurger {
    display: block;
  }
}
@media screen and (max-width: 475px) {
  .NavBar {
    &-logo {
      font-size: 18px;
      font-weight: 600;
      color: white;

      span {
        color: $textColor;
      }
    }
  }
}
</style>
