<template>
  <div class="codes">
    <h2>{{ $t('profile.access-codes') }}</h2>
    <button class="black-btn" @click="$refs.addCode.open()">
      <span>+</span>
      {{ $t('profile.add-code') }}
    </button>
    <ul class="codes-list">
      <li
        v-for="(code, index) in codes"
        :key="index"
        :class="isEven(index)"
        @click="editCode(index)"
      >
        <div>{{ code.code }}</div>
      </li>
    </ul>
    <SweetModal
      :title="$t('profile.add-code')"
      ref="addCode"
      overlay-theme="dark"
      class="dark-modal"
    >
      <form @submit.prevent>
        <div class="input-container">
          <label for="code">{{ $t('profile.new-code') }}</label>
          <input type="text" name="code" v-model="newCode" required />
        </div>
        <div class="input-container checkbox">
          <input type="checkbox" name="code" v-model="newCodeType" />
          <label for="newCodeType">{{ $t('profile.new-code-type') }}</label>
          <div class="checkbox-input" />
        </div>
        <button class="primary-btn" type="submit" @click="sendNewCode">
          {{ $t('common.send') }}
        </button>
      </form>
    </SweetModal>
  </div>
</template>

<script>
import api from '@/services/api.service.js'
export default {
  name: 'Codes',
  data() {
    return {
      codes: [],
      newCode: '',
      newCodeType: 0
    }
  },
  created() {
    console.log('codes')
    this.getCodes()
  },
  methods: {
    isEven(index) {
      if (index % 2 === 0) {
        return 'grey'
      } else {
        return ''
      }
    },
    editCode() {
      this.$refs.editCode.open()
    },
    async getCodes() {
      const res = await api.get.codes()
      this.codes = res.data.codes
    },
    async sendNewCode() {
      console.log(this.newCode, this.newCodeType, Number(this.newCodeType))
      const data = {
        code: this.newCode,
        type: Number(this.newCodeType)
      }
      const res = await api.post.code(data)
      if (res.data.status == 1) {
        this.getCodes()
        this.$refs.addCode.close()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.codes {
  .black-btn {
    margin: auto;
    @include flex(space-around);
    font-size: 16px;
    span {
      font-size: 1.8em;
      color: #fff;
      margin-right: 12px;
    }
  }
  form {
    @include flex($direction: column, $align: start);
    input[type='text'] {
      width: 100%;
    }
    button {
      align-self: center;
    }
    .checkbox {
      label {
        margin-left: 5px;
      }
    }
  }
  ul {
    padding: 0px;
    list-style-type: none;
    border: 1px solid #fff;
    li {
      @include flex(space-between);
      padding: 6px 12px;
      &.grey {
        background: $darkGrey;
      }
      &:hover {
        cursor: pointer;
        background: #fff;
        transition: 0.2 all ease-in-out;
      }
      img {
        width: 24px;
      }
    }
  }
}
</style>
