<template>
  <div class="Footer">
    <div class="footer-line" />
    <div class="footer-row">
      <div class="footer-column">
        <p>Encuéntranos en</p>
        <ul>
          <li>
            <i class="fab fa-twitter" style="color:#3592DA" />
            <a href="https://twitter.com/poetica2cero" target="_blank">
              Twitter</a
            >
          </li>
          <li>
            <i class="fab fa-facebook" style="color:##0880EC" />
            <a href="https://www.facebook.com/poetica2.0" target="_blank">
              Facebook</a
            >
          </li>
          <li>
            <i class="fab fa-instagram" style="color:#CE589A" />
            <a href="https://www.instagram.com/poetica_2.0/" target="_blank">
              Instagram</a
            >
          </li>
          <li>
            <i class="fab fa-youtube" style="color:#E54D03" />
            <a
              href="https://www.youtube.com/channel/UCWefTmVg84aWcrilWWzg5og"
              target="_blank"
            >
              Youtube</a
            >
          </li>
        </ul>
      </div>
      <div class="footer-column">
        <p>Contacto</p>
        <p>
          <a href="mailto:info@poetica2puntocero.com"
            >info@poetica2puntocero.com</a
          >
        </p>
      </div>
      <div class="footer-column">
        <ul class="legal">
          <li>
            <RouterLink to="/legal">
              {{ $t('footer.legal') }}
            </RouterLink>
          </li>
          <li>
            <RouterLink to="/thanks">
              {{ $t('footer.thanks') }}
            </RouterLink>
          </li>
        </ul>
      </div>
    </div>
    <div class="footer-img-container">
      <img src="../assets/footer/ivace2022.png" alt="footer" />
      <p>Ⓒ Copyright 2022 Poética 2.0</p>
    </div>
    <div class="logos">
      <a
        v-for="logo in logos"
        :href="logo.link"
        :title="logo.name"
        :key="logo.key"
        class="elem"
        target="_blank"
      >
        <img :src="logo.logo" :alt="logo.name" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      logos: [
        {
          key: 0,
          name: 'Sempre teua',
          logo: require('../assets/footer/sempre.png'),
          link: 'https://sempreteua.gva.es/va/'
        },
        {
          key: 1,
          name: 'Generalitat Valenciana',
          logo: require('../assets/footer/conselleria.jpg'),
          link: 'https://www.gva.es/'
        },
        {
          key: 2,
          name: 'Ajuntament de València',
          logo: require('../assets/footer/aytovlc.png'),
          link: 'https://www.valencia.es/'
        },
        {
          key: 3,
          name: 'Instituto Cervantes',
          logo: require('../assets/footer/cervantes.jpg'),
          link: 'https://www.cervantes.es/'
        },
        {
          key: 4,
          name: 'Diputació de València',
          logo: require('../assets/footer/dipu.jpg'),
          link: 'https://www.dival.es/'
        },
        {
          key: 5,
          name: 'Facultad de Filología. Universidad Complutense',
          logo: require('../assets/footer/filologia.jpg'),
          link: 'https://filologia.ucm.es/'
        },
        {
          key: 6,
          name: 'Carme Teatre',
          logo: require('../assets/footer/carme.png'),
          link: 'https://carmeteatre.com/'
        },
        {
          key: 7,
          name: 'Grupo Marquina',
          logo: require('../assets/footer/marquina.png'),
          link: 'https://www.grupomarquina.es/'
        },
        {
          key: 8,
          name: 'Institut Valencià de la Cultura',
          logo: require('../assets/footer/ivc.png'),
          link: 'https://ivc.gva.es/'
        },
        {
          key: 9,
          name: 'La Nau',
          logo: require('../assets/footer/nau.png'),
          link:
            'https://www.uv.es/uvweb/cultura/es/nau/centro-cultural-nau/presentacion-1285866274374.html'
        },
        {
          key: 10,
          name: 'Teatre Talia',
          logo: require('../assets/footer/talia.png'),
          link: 'https://www.teatretalia.es/'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.Footer {
  .footer-line {
    width: 95%;
    height: 1px;
    border: 1px solid gray;
    margin: 0px;
    margin-bottom: 40px;
  }

  @include flex();
  flex-direction: column;
  width: 100%;

  .footer-row {
    @include flex();
    width: 100%;
    @include flex();
    justify-content: space-evenly;
    .footer-column {
      height: 138px;
      text-align: center;
      ul {
        padding: 0px;
        text-align: left;
        li {
          list-style: none;
          margin-bottom: 15px;
          cursor: pointer;
        }
      }
      .legal,
      .legal-title {
        text-align: right;
      }
    }
  }
  .footer-img-container {
    padding: 20px;
    padding-bottom: 40px;
    margin: 20px 0px;
    p {
      margin: 0px;
    }
  }
  .logos {
    width: 90%;
    flex-wrap: wrap;
    @include flex();
    gap: 10px;
    margin-bottom: 30px;
    .elem {
      width: 100px;
      height: 80px;
      background: white;
      @include flex();
      padding: 5px;
      border-radius: 4px;
    }
  }
}

@media screen and (max-width: 625px) {
  .Footer {
    padding: 0px 10px;
    .footer-row {
      .footer-column {
        li,
        p {
          font-size: 10px;
        }
      }
    }
    .footer-img-container {
      img {
        width: 330px;
      }
      p {
        font-size: 12px;
      }
    }
  }
}
</style>
