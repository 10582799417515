<template>
  <div class="institution">
    <template v-if="user.profile == 1">
      <div class="buttons-bar">
        <button
          v-for="(but, index) in buttons"
          @click="setComponent(but.component)"
          :key="index"
          :class="isActive(but.component) && 'active'"
        >
          {{ but.text }}
        </button>
      </div>
      <Codes v-if="component === 'codes'" />
      <Users v-if="component === 'users'" />
      <Domains v-if="component === 'domains'" />
      <Ips v-if="component === 'ips'" />
    </template>
    <template v-else>
      <h2 class="no-inst" v-html="$t('profile.convert-to-institution')" />
      <div class="to-inst">
        <form>
          <div class="input-container">
            <label for="name">{{ $t('profile.institution-name') }}</label>
            <input type="text" name="name" v-model="newInstitution.name" />
            <div
              class="error"
              v-if="submittedNew && !$v.institution.name.required"
            >
              {{ $t('errors.required') }}
            </div>
          </div>
          <div class="input-container">
            <label for="message">{{ $t('common.message') }}</label>
            <textarea v-model="newInstitution.message" />
          </div>
        </form>
        <button class="save" type="submit" @click="sendNewInst">
          {{ $t('common.send') }}
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import Codes from './institution/Codes'
import Users from './institution/Users'
import Domains from './institution/Domains'
import Ips from './institution/Ips'
import { mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'InsitutionProfile',
  components: { Codes, Users, Domains, Ips },
  data() {
    return {
      buttons: [
        { text: this.$t('profile.access-codes'), component: 'codes' },
        { text: this.$t('profile.users'), component: 'users' }
        /*{ text: this.$t('profile.authorized-domains'), component: 'domains' },
        { text: this.$t('profile.authorized-ips'), component: 'ips' }*/
      ],
      component: 'codes',
      newInstitution: {
        name: '',
        message: ''
      },
      submittedNew: false
    }
  },
  computed: {
    ...mapState(['user'])
  },
  methods: {
    setComponent(comp) {
      this.component = comp
    },
    isActive(compo) {
      if (this.component == compo) {
        return true
      } else {
        return false
      }
    }
    // sendNewInst() {
    //   //console.log('send new')
    // }
  },
  validators: {
    newInstitution: {
      name: {
        required
      }
    }
  }
}
</script>

<style lang="scss">
.institution {
  margin-top: 18px;
  .buttons-bar {
    @include flex(start);
    margin-bottom: 15px;
    button {
      margin-right: 15px;
      padding: 6px;
      background: $primary;
      color: #fff;
      border: 1px solid #fff;
      cursor: pointer;
      transition: 0.2s all ease-in-out;
      &.active {
        background: white;
        color: black;
      }
    }
  }
  .no-inst {
    text-align: center;
  }
}
.input-container {
  @include flex(start, flex-start, column);
  margin-bottom: 12px;
  label {
    color: $textColor;
    margin-bottom: 6px;
  }
  input,
  textarea {
    background: $darkGrey;
    border: 0px;
    padding: 8px 4px;
    width: 100%;
    color: #fff;
  }
  select {
    background: $darkGrey;
    border: 0px;
    padding: 8px 4px;
    width: 100%;
    color: #fff;
    border-radius: 0px;
  }
}
.save {
  background: #fff;
  color: $primary;
  border: 1px solid white;
  border-radius: 0px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  padding: 6px 12px;
  transition: 0.2s all ease-in-out;
  &:hover {
    background: black;
    color: white;
    cursor: pointer;
  }
}
.to-inst {
  border: 0.5px solid #fff;
  margin-top: 18px;
  padding: 18px;
  @include flex(center, false, true);
  form {
    width: 100%;
  }
}
</style>
